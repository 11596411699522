.vl-contact-card__content {
    background-color: $alt-bg;
    padding: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6rem;
    line-height: 2rem;
}

.vl-contact-card__data__content {
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
    width: 70%;
    margin-bottom: 1rem;
    white-space: normal;
}

.vl-infoblock__title {
    font-family: "Flanders Art Sans", sans-serif;
}