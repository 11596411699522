.vl-typography h3 {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    line-height: 1.2;
}

.vl-title--h1 {
    font-size: 4.8rem;
    font-weight: 300;
    margin-bottom: 6rem;
    line-height: 1.2;
}

.vl-typography a {
    text-decoration: underline;
}

a {
	color: $theme-primary-color;
	-webkit-text-decoration-skip: ink;
	text-decoration-skip: ink;
	cursor: pointer;
}

a:focus,
a:hover {
	text-decoration: none;
	color: $theme-primary-color-dark;
}

a:visited {
    color: $theme-primary-color;
}