//old tabs styling

.vl-tab .vl-tab__link:hover {
    border-top: 3px solid $vl-disabled-color;
    border-bottom: none;
}

.vl-tab__link {
    display: block;
    padding: 1rem 1.1rem 1rem 1.2rem;
    transition: all .2s;
    border-bottom: 3px solid transparent;
    color: $theme-primary-color;
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: none;
}

.vl-functional-header .vl-tab .vl-tab__link:visited,
.vl-functional-header .vl-tab .vl-tab__link .vl-link__icon {
    color: $theme-primary-color;
}

.vl-tab__link:focus,
.vl-tab__link:hover {
    background-color: transparent;
    border-bottom-color: $text-color;
    color: darken($theme-primary-color-dark, 5%);
}

.vl-tab:not(:first-child) .vl-tab__link {
    position: relative;
    border-left: 0;
}

.vl-tab .vl-tab__link {
    padding: 1rem 1.2rem .7rem;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-top: 3px solid transparent;
}

.vl-tab__link {
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

.vl-tab:not(:first-child) .vl-tab__link::after {
    position: absolute;
    content: "";
    top: calc(50% - (1.8rem / 2));
    bottom: 0;
    left: 0;
    height: 1.8rem;
    width: 1px;
    background: $theme-disabled-color;
}

.vl-tab .vl-tab__link:hover {
    border-top: 3px solid $theme-disabled-color;
}

.vl-tab .vl-tab__link {
    padding: 1rem 1.2rem .7rem;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-top: 3px solid transparent;
}

.vl-tab__link:focus,
.vl-tab__link:hover {
    background-color: transparent;
    border-bottom-color: $text-color;
    color: $theme-primary-color-dark;
}

.vl-tab__link {
    display: block;
    padding: 1rem 1.1rem 1rem 1.2rem;
    transition: all .2s;
    border-bottom: 3px solid transparent;
    color: $theme-primary-color;
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: none;
}

.vl-functional-header .vl-tab .vl-tab__link:hover {
    border-top: 3px solid $theme-disabled-color;
}

.vl-functional-header .vl-tab .vl-tab__link {
    padding: 1rem 1.2rem .7rem;
    border: 0;
    border-top-color: currentcolor;
    border-top-style: none;
    border-top-width: 0px;
    border-top: 3px solid transparent;
}

.vl-tab--active .vl-tab__link {
    color: $text-color !important;
    border-top: 3px solid $text-color !important;
    border-bottom: none;
}

.vl-tab__link:focus,
.vl-tab__link:hover {
    background-color: transparent;
    border-bottom-color: $text-color;
    color: $theme-primary-color-dark;
}

.vl-tab__link {
    display: block;
    padding: 1rem 1.1rem 1rem 1.2rem;
    transition: all .2s;
    border-bottom: 3px solid transparent;
    color: $theme-primary-color;
    font-family: "Flanders Art Sans", sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    text-decoration: none;
}


.vl-tab {
    display: inline-block;
    position: relative;
    text-align: left;
    vertical-align: top;
    top: 3px;
    border-bottom: none;
}