$icon-font-location: "/assets/font/iconfont/" !default;
$icon-family: "vlaanderen-icon";

// sass-lint:disable mixin-name-format max-file-line-count class-name-format
@include font-face($icon-family, $icon-font-location, $icon-family, normal, normal);

// ### ICON: add
@mixin vi-add {
	&::before {
		content: "\f101";
	}
}

@mixin vi-add-after {
	&::after {
		content: "\f101";
	}
}

.vl-vi-add {
	@include vi-add;
}

.vl-vi-add--after {
	@include vi-add-after;
}

// ### ICON: addressbook
@mixin vi-addressbook {
	&::before {
		content: "\f102";
	}
}

@mixin vi-addressbook-after {
	&::after {
		content: "\f102";
	}
}

.vl-vi-addressbook {
	@include vi-addressbook;
}

.vl-vi-addressbook--after {
	@include vi-addressbook-after;
}

// ### ICON: airplane
@mixin vi-airplane {
	&::before {
		content: "\f103";
	}
}

@mixin vi-airplane-after {
	&::after {
		content: "\f103";
	}
}

.vl-vi-airplane {
	@include vi-airplane;
}

.vl-vi-airplane--after {
	@include vi-airplane-after;
}

// ### ICON: alarm-silent
@mixin vi-alarm-silent {
	&::before {
		content: "\f104";
	}
}

@mixin vi-alarm-silent-after {
	&::after {
		content: "\f104";
	}
}

.vl-vi-alarm-silent {
	@include vi-alarm-silent;
}

.vl-vi-alarm-silent--after {
	@include vi-alarm-silent-after;
}

// ### ICON: alarm
@mixin vi-alarm {
	&::before {
		content: "\f105";
	}
}

@mixin vi-alarm-after {
	&::after {
		content: "\f105";
	}
}

.vl-vi-alarm {
	@include vi-alarm;
}

.vl-vi-alarm--after {
	@include vi-alarm-after;
}

// ### ICON: alert-circle-filled
@mixin vi-alert-circle-filled {
	&::before {
		content: "\f106";
	}
}

@mixin vi-alert-circle-filled-after {
	&::after {
		content: "\f106";
	}
}

.vl-vi-alert-circle-filled {
	@include vi-alert-circle-filled;
}

.vl-vi-alert-circle-filled--after {
	@include vi-alert-circle-filled-after;
}

// ### ICON: alert-circle
@mixin vi-alert-circle {
	&::before {
		content: "\f107";
	}
}

@mixin vi-alert-circle-after {
	&::after {
		content: "\f107";
	}
}

.vl-vi-alert-circle {
	@include vi-alert-circle;
}

.vl-vi-alert-circle--after {
	@include vi-alert-circle-after;
}

// ### ICON: alert-small
@mixin vi-alert-small {
	&::before {
		content: "\f108";
	}
}

@mixin vi-alert-small-after {
	&::after {
		content: "\f108";
	}
}

.vl-vi-alert-small {
	@include vi-alert-small;
}

.vl-vi-alert-small--after {
	@include vi-alert-small-after;
}

// ### ICON: alert-triangle-filled
@mixin vi-alert-triangle-filled {
	&::before {
		content: "\f109";
	}
}

@mixin vi-alert-triangle-filled-after {
	&::after {
		content: "\f109";
	}
}

.vl-vi-alert-triangle-filled {
	@include vi-alert-triangle-filled;
}

.vl-vi-alert-triangle-filled--after {
	@include vi-alert-triangle-filled-after;
}

// ### ICON: alert-triangle
@mixin vi-alert-triangle {
	&::before {
		content: "\f10a";
	}
}

@mixin vi-alert-triangle-after {
	&::after {
		content: "\f10a";
	}
}

.vl-vi-alert-triangle {
	@include vi-alert-triangle;
}

.vl-vi-alert-triangle--after {
	@include vi-alert-triangle-after;
}

// ### ICON: align-center
@mixin vi-align-center {
	&::before {
		content: "\f10b";
	}
}

@mixin vi-align-center-after {
	&::after {
		content: "\f10b";
	}
}

.vl-vi-align-center {
	@include vi-align-center;
}

.vl-vi-align-center--after {
	@include vi-align-center-after;
}

// ### ICON: align-justify
@mixin vi-align-justify {
	&::before {
		content: "\f10c";
	}
}

@mixin vi-align-justify-after {
	&::after {
		content: "\f10c";
	}
}

.vl-vi-align-justify {
	@include vi-align-justify;
}

.vl-vi-align-justify--after {
	@include vi-align-justify-after;
}

// ### ICON: align-left
@mixin vi-align-left {
	&::before {
		content: "\f10d";
	}
}

@mixin vi-align-left-after {
	&::after {
		content: "\f10d";
	}
}

.vl-vi-align-left {
	@include vi-align-left;
}

.vl-vi-align-left--after {
	@include vi-align-left-after;
}

// ### ICON: align-right
@mixin vi-align-right {
	&::before {
		content: "\f10e";
	}
}

@mixin vi-align-right-after {
	&::after {
		content: "\f10e";
	}
}

.vl-vi-align-right {
	@include vi-align-right;
}

.vl-vi-align-right--after {
	@include vi-align-right-after;
}

// ### ICON: area
@mixin vi-area {
	&::before {
		content: "\f10f";
	}
}

@mixin vi-area-after {
	&::after {
		content: "\f10f";
	}
}

.vl-vi-area {
	@include vi-area;
}

.vl-vi-area--after {
	@include vi-area-after;
}

// ### ICON: arrange-1-to-9
@mixin vi-arrange-1-to-9 {
	&::before {
		content: "\f110";
	}
}

@mixin vi-arrange-1-to-9-after {
	&::after {
		content: "\f110";
	}
}

.vl-vi-arrange-1-to-9 {
	@include vi-arrange-1-to-9;
}

.vl-vi-arrange-1-to-9--after {
	@include vi-arrange-1-to-9-after;
}

// ### ICON: arrange-9-to-1
@mixin vi-arrange-9-to-1 {
	&::before {
		content: "\f111";
	}
}

@mixin vi-arrange-9-to-1-after {
	&::after {
		content: "\f111";
	}
}

.vl-vi-arrange-9-to-1 {
	@include vi-arrange-9-to-1;
}

.vl-vi-arrange-9-to-1--after {
	@include vi-arrange-9-to-1-after;
}

// ### ICON: arrange-a-to-z
@mixin vi-arrange-a-to-z {
	&::before {
		content: "\f112";
	}
}

@mixin vi-arrange-a-to-z-after {
	&::after {
		content: "\f112";
	}
}

.vl-vi-arrange-a-to-z {
	@include vi-arrange-a-to-z;
}

.vl-vi-arrange-a-to-z--after {
	@include vi-arrange-a-to-z-after;
}

// ### ICON: arrange-z-to-a
@mixin vi-arrange-z-to-a {
	&::before {
		content: "\f113";
	}
}

@mixin vi-arrange-z-to-a-after {
	&::after {
		content: "\f113";
	}
}

.vl-vi-arrange-z-to-a {
	@include vi-arrange-z-to-a;
}

.vl-vi-arrange-z-to-a--after {
	@include vi-arrange-z-to-a-after;
}

// ### ICON: arrow-bottom
@mixin vi-arrow-bottom {
	&::before {
		content: "\f114";
	}
}

@mixin vi-arrow-bottom-after {
	&::after {
		content: "\f114";
	}
}

.vl-vi-arrow-bottom {
	@include vi-arrow-bottom;
}

.vl-vi-arrow-bottom--after {
	@include vi-arrow-bottom-after;
}

// ### ICON: arrow-down-fat
@mixin vi-arrow-down-fat {
	&::before {
		content: "\f115";
	}
}

@mixin vi-arrow-down-fat-after {
	&::after {
		content: "\f115";
	}
}

.vl-vi-arrow-down-fat {
	@include vi-arrow-down-fat;
}

.vl-vi-arrow-down-fat--after {
	@include vi-arrow-down-fat-after;
}

// ### ICON: arrow-down-thin
@mixin vi-arrow-down-thin {
	&::before {
		content: "\f116";
	}
}

@mixin vi-arrow-down-thin-after {
	&::after {
		content: "\f116";
	}
}

.vl-vi-arrow-down-thin {
	@include vi-arrow-down-thin;
}

.vl-vi-arrow-down-thin--after {
	@include vi-arrow-down-thin-after;
}

// ### ICON: arrow-down
@mixin vi-arrow-down {
	&::before {
		content: "\f117";
	}
}

@mixin vi-arrow-down-after {
	&::after {
		content: "\f117";
	}
}

.vl-vi-arrow-down {
	@include vi-arrow-down;
}

.vl-vi-arrow-down--after {
	@include vi-arrow-down-after;
}

// ### ICON: arrow-freemove
@mixin vi-arrow-freemove {
	&::before {
		content: "\f118";
	}
}

@mixin vi-arrow-freemove-after {
	&::after {
		content: "\f118";
	}
}

.vl-vi-arrow-freemove {
	@include vi-arrow-freemove;
}

.vl-vi-arrow-freemove--after {
	@include vi-arrow-freemove-after;
}

// ### ICON: arrow-left-fat
@mixin vi-arrow-left-fat {
	&::before {
		content: "\f119";
	}
}

@mixin vi-arrow-left-fat-after {
	&::after {
		content: "\f119";
	}
}

.vl-vi-arrow-left-fat {
	@include vi-arrow-left-fat;
}

.vl-vi-arrow-left-fat--after {
	@include vi-arrow-left-fat-after;
}

// ### ICON: arrow-left-thin
@mixin vi-arrow-left-thin {
	&::before {
		content: "\f11a";
	}
}

@mixin vi-arrow-left-thin-after {
	&::after {
		content: "\f11a";
	}
}

.vl-vi-arrow-left-thin {
	@include vi-arrow-left-thin;
}

.vl-vi-arrow-left-thin--after {
	@include vi-arrow-left-thin-after;
}

// ### ICON: arrow-left
@mixin vi-arrow-left {
	&::before {
		content: "\f11b";
	}
}

@mixin vi-arrow-left-after {
	&::after {
		content: "\f11b";
	}
}

.vl-vi-arrow-left {
	@include vi-arrow-left;
}

.vl-vi-arrow-left--after {
	@include vi-arrow-left-after;
}

// ### ICON: arrow-right-fat
@mixin vi-arrow-right-fat {
	&::before {
		content: "\f11c";
	}
}

@mixin vi-arrow-right-fat-after {
	&::after {
		content: "\f11c";
	}
}

.vl-vi-arrow-right-fat {
	@include vi-arrow-right-fat;
}

.vl-vi-arrow-right-fat--after {
	@include vi-arrow-right-fat-after;
}

// ### ICON: arrow-right-thin
@mixin vi-arrow-right-thin {
	&::before {
		content: "\f11d";
	}
}

@mixin vi-arrow-right-thin-after {
	&::after {
		content: "\f11d";
	}
}

.vl-vi-arrow-right-thin {
	@include vi-arrow-right-thin;
}

.vl-vi-arrow-right-thin--after {
	@include vi-arrow-right-thin-after;
}

// ### ICON: arrow-right
@mixin vi-arrow-right {
	&::before {
		content: "\f11e";
	}
}

@mixin vi-arrow-right-after {
	&::after {
		content: "\f11e";
	}
}

.vl-vi-arrow-right {
	@include vi-arrow-right;
}

.vl-vi-arrow-right--after {
	@include vi-arrow-right-after;
}

// ### ICON: arrow-thin
@mixin vi-arrow-thin {
	&::before {
		content: "\f11f";
	}
}

@mixin vi-arrow-thin-after {
	&::after {
		content: "\f11f";
	}
}

.vl-vi-arrow-thin {
	@include vi-arrow-thin;
}

.vl-vi-arrow-thin--after {
	@include vi-arrow-thin-after;
}

// ### ICON: arrow-up-fat
@mixin vi-arrow-up-fat {
	&::before {
		content: "\f120";
	}
}

@mixin vi-arrow-up-fat-after {
	&::after {
		content: "\f120";
	}
}

.vl-vi-arrow-up-fat {
	@include vi-arrow-up-fat;
}

.vl-vi-arrow-up-fat--after {
	@include vi-arrow-up-fat-after;
}

// ### ICON: arrow-up-thin
@mixin vi-arrow-up-thin {
	&::before {
		content: "\f121";
	}
}

@mixin vi-arrow-up-thin-after {
	&::after {
		content: "\f121";
	}
}

.vl-vi-arrow-up-thin {
	@include vi-arrow-up-thin;
}

.vl-vi-arrow-up-thin--after {
	@include vi-arrow-up-thin-after;
}

// ### ICON: arrow-up
@mixin vi-arrow-up {
	&::before {
		content: "\f122";
	}
}

@mixin vi-arrow-up-after {
	&::after {
		content: "\f122";
	}
}

.vl-vi-arrow-up {
	@include vi-arrow-up;
}

.vl-vi-arrow-up--after {
	@include vi-arrow-up-after;
}

// ### ICON: arrow
@mixin vi-arrow {
	&::before {
		content: "\f123";
	}
}

@mixin vi-arrow-after {
	&::after {
		content: "\f123";
	}
}

.vl-vi-arrow {
	@include vi-arrow;
}

.vl-vi-arrow--after {
	@include vi-arrow-after;
}

// ### ICON: asterisk
@mixin vi-asterisk {
	&::before {
		content: "\f124";
	}
}

@mixin vi-asterisk-after {
	&::after {
		content: "\f124";
	}
}

.vl-vi-asterisk {
	@include vi-asterisk;
}

.vl-vi-asterisk--after {
	@include vi-asterisk-after;
}

// ### ICON: audio-description
@mixin vi-audio-description {
	&::before {
		content: "\f125";
	}
}

@mixin vi-audio-description-after {
	&::after {
		content: "\f125";
	}
}

.vl-vi-audio-description {
	@include vi-audio-description;
}

.vl-vi-audio-description--after {
	@include vi-audio-description-after;
}

// ### ICON: back
@mixin vi-back {
	&::before {
		content: "\f126";
	}
}

@mixin vi-back-after {
	&::after {
		content: "\f126";
	}
}

.vl-vi-back {
	@include vi-back;
}

.vl-vi-back--after {
	@include vi-back-after;
}

// ### ICON: ban
@mixin vi-ban {
	&::before {
		content: "\f127";
	}
}

@mixin vi-ban-after {
	&::after {
		content: "\f127";
	}
}

.vl-vi-ban {
	@include vi-ban;
}

.vl-vi-ban--after {
	@include vi-ban-after;
}

// ### ICON: bell
@mixin vi-bell {
	&::before {
		content: "\f128";
	}
}

@mixin vi-bell-after {
	&::after {
		content: "\f128";
	}
}

.vl-vi-bell {
	@include vi-bell;
}

.vl-vi-bell--after {
	@include vi-bell-after;
}

// ### ICON: bike-closed-criterium
@mixin vi-bike-closed-criterium {
	&::before {
		content: "\f129";
	}
}

@mixin vi-bike-closed-criterium-after {
	&::after {
		content: "\f129";
	}
}

.vl-vi-bike-closed-criterium {
	@include vi-bike-closed-criterium;
}

.vl-vi-bike-closed-criterium--after {
	@include vi-bike-closed-criterium-after;
}

// ### ICON: bike-open-criterium
@mixin vi-bike-open-criterium {
	&::before {
		content: "\f12a";
	}
}

@mixin vi-bike-open-criterium-after {
	&::after {
		content: "\f12a";
	}
}

.vl-vi-bike-open-criterium {
	@include vi-bike-open-criterium;
}

.vl-vi-bike-open-criterium--after {
	@include vi-bike-open-criterium-after;
}

// ### ICON: bike
@mixin vi-bike {
	&::before {
		content: "\f12b";
	}
}

@mixin vi-bike-after {
	&::after {
		content: "\f12b";
	}
}

.vl-vi-bike {
	@include vi-bike;
}

.vl-vi-bike--after {
	@include vi-bike-after;
}

// ### ICON: bin
@mixin vi-bin {
	&::before {
		content: "\f12c";
	}
}

@mixin vi-bin-after {
	&::after {
		content: "\f12c";
	}
}

.vl-vi-bin {
	@include vi-bin;
}

.vl-vi-bin--after {
	@include vi-bin-after;
}

// ### ICON: binoculars
@mixin vi-binoculars {
	&::before {
		content: "\f12d";
	}
}

@mixin vi-binoculars-after {
	&::after {
		content: "\f12d";
	}
}

.vl-vi-binoculars {
	@include vi-binoculars;
}

.vl-vi-binoculars--after {
	@include vi-binoculars-after;
}

// ### ICON: boat-ship
@mixin vi-boat-ship {
	&::before {
		content: "\f12e";
	}
}

@mixin vi-boat-ship-after {
	&::after {
		content: "\f12e";
	}
}

.vl-vi-boat-ship {
	@include vi-boat-ship;
}

.vl-vi-boat-ship--after {
	@include vi-boat-ship-after;
}

// ### ICON: bold
@mixin vi-bold {
	&::before {
		content: "\f12f";
	}
}

@mixin vi-bold-after {
	&::after {
		content: "\f12f";
	}
}

.vl-vi-bold {
	@include vi-bold;
}

.vl-vi-bold--after {
	@include vi-bold-after;
}

// ### ICON: book
@mixin vi-book {
	&::before {
		content: "\f130";
	}
}

@mixin vi-book-after {
	&::after {
		content: "\f130";
	}
}

.vl-vi-book {
	@include vi-book;
}

.vl-vi-book--after {
	@include vi-book-after;
}

// ### ICON: bookmark-alt-1
@mixin vi-bookmark-alt-1 {
	&::before {
		content: "\f131";
	}
}

@mixin vi-bookmark-alt-1-after {
	&::after {
		content: "\f131";
	}
}

.vl-vi-bookmark-alt-1 {
	@include vi-bookmark-alt-1;
}

.vl-vi-bookmark-alt-1--after {
	@include vi-bookmark-alt-1-after;
}

// ### ICON: bookmark-alt-2
@mixin vi-bookmark-alt-2 {
	&::before {
		content: "\f132";
	}
}

@mixin vi-bookmark-alt-2-after {
	&::after {
		content: "\f132";
	}
}

.vl-vi-bookmark-alt-2 {
	@include vi-bookmark-alt-2;
}

.vl-vi-bookmark-alt-2--after {
	@include vi-bookmark-alt-2-after;
}

// ### ICON: bookmark
@mixin vi-bookmark {
	&::before {
		content: "\f133";
	}
}

@mixin vi-bookmark-after {
	&::after {
		content: "\f133";
	}
}

.vl-vi-bookmark {
	@include vi-bookmark;
}

.vl-vi-bookmark--after {
	@include vi-bookmark-after;
}

// ### ICON: breadcrumb-separator
@mixin vi-breadcrumb-separator {
	&::before {
		content: "\f134";
	}
}

@mixin vi-breadcrumb-separator-after {
	&::after {
		content: "\f134";
	}
}

.vl-vi-breadcrumb-separator {
	@include vi-breadcrumb-separator;
}

.vl-vi-breadcrumb-separator--after {
	@include vi-breadcrumb-separator-after;
}

// ### ICON: briefcase
@mixin vi-briefcase {
	&::before {
		content: "\f135";
	}
}

@mixin vi-briefcase-after {
	&::after {
		content: "\f135";
	}
}

.vl-vi-briefcase {
	@include vi-briefcase;
}

.vl-vi-briefcase--after {
	@include vi-briefcase-after;
}

// ### ICON: building
@mixin vi-building {
	&::before {
		content: "\f136";
	}
}

@mixin vi-building-after {
	&::after {
		content: "\f136";
	}
}

.vl-vi-building {
	@include vi-building;
}

.vl-vi-building--after {
	@include vi-building-after;
}

// ### ICON: bullet
@mixin vi-bullet {
	&::before {
		content: "\f137";
	}
}

@mixin vi-bullet-after {
	&::after {
		content: "\f137";
	}
}

.vl-vi-bullet {
	@include vi-bullet;
}

.vl-vi-bullet--after {
	@include vi-bullet-after;
}

// ### ICON: burger-alt
@mixin vi-burger-alt {
	&::before {
		content: "\f138";
	}
}

@mixin vi-burger-alt-after {
	&::after {
		content: "\f138";
	}
}

.vl-vi-burger-alt {
	@include vi-burger-alt;
}

.vl-vi-burger-alt--after {
	@include vi-burger-alt-after;
}

// ### ICON: burger
@mixin vi-burger {
	&::before {
		content: "\f139";
	}
}

@mixin vi-burger-after {
	&::after {
		content: "\f139";
	}
}

.vl-vi-burger {
	@include vi-burger;
}

.vl-vi-burger--after {
	@include vi-burger-after;
}

// ### ICON: burgerprofiel
@mixin vi-burgerprofiel {
	&::before {
		content: "\f13a";
	}
}

@mixin vi-burgerprofiel-after {
	&::after {
		content: "\f13a";
	}
}

.vl-vi-burgerprofiel {
	@include vi-burgerprofiel;
}

.vl-vi-burgerprofiel--after {
	@include vi-burgerprofiel-after;
}

// ### ICON: bus
@mixin vi-bus {
	&::before {
		content: "\f13b";
	}
}

@mixin vi-bus-after {
	&::after {
		content: "\f13b";
	}
}

.vl-vi-bus {
	@include vi-bus;
}

.vl-vi-bus--after {
	@include vi-bus-after;
}

// ### ICON: business-graph-bar
@mixin vi-business-graph-bar {
	&::before {
		content: "\f13c";
	}
}

@mixin vi-business-graph-bar-after {
	&::after {
		content: "\f13c";
	}
}

.vl-vi-business-graph-bar {
	@include vi-business-graph-bar;
}

.vl-vi-business-graph-bar--after {
	@include vi-business-graph-bar-after;
}

// ### ICON: business-graph-pie
@mixin vi-business-graph-pie {
	&::before {
		content: "\f13d";
	}
}

@mixin vi-business-graph-pie-after {
	&::after {
		content: "\f13d";
	}
}

.vl-vi-business-graph-pie {
	@include vi-business-graph-pie;
}

.vl-vi-business-graph-pie--after {
	@include vi-business-graph-pie-after;
}

// ### ICON: cake
@mixin vi-cake {
	&::before {
		content: "\f13e";
	}
}

@mixin vi-cake-after {
	&::after {
		content: "\f13e";
	}
}

.vl-vi-cake {
	@include vi-cake;
}

.vl-vi-cake--after {
	@include vi-cake-after;
}

// ### ICON: calculator
@mixin vi-calculator {
	&::before {
		content: "\f13f";
	}
}

@mixin vi-calculator-after {
	&::after {
		content: "\f13f";
	}
}

.vl-vi-calculator {
	@include vi-calculator;
}

.vl-vi-calculator--after {
	@include vi-calculator-after;
}

// ### ICON: calendar-add
@mixin vi-calendar-add {
	&::before {
		content: "\f140";
	}
}

@mixin vi-calendar-add-after {
	&::after {
		content: "\f140";
	}
}

.vl-vi-calendar-add {
	@include vi-calendar-add;
}

.vl-vi-calendar-add--after {
	@include vi-calendar-add-after;
}

// ### ICON: calendar-check
@mixin vi-calendar-check {
	&::before {
		content: "\f141";
	}
}

@mixin vi-calendar-check-after {
	&::after {
		content: "\f141";
	}
}

.vl-vi-calendar-check {
	@include vi-calendar-check;
}

.vl-vi-calendar-check--after {
	@include vi-calendar-check-after;
}

// ### ICON: calendar-subtract
@mixin vi-calendar-subtract {
	&::before {
		content: "\f142";
	}
}

@mixin vi-calendar-subtract-after {
	&::after {
		content: "\f142";
	}
}

.vl-vi-calendar-subtract {
	@include vi-calendar-subtract;
}

.vl-vi-calendar-subtract--after {
	@include vi-calendar-subtract-after;
}

// ### ICON: calendar
@mixin vi-calendar {
	&::before {
		content: "\f143";
	}
}

@mixin vi-calendar-after {
	&::after {
		content: "\f143";
	}
}

.vl-vi-calendar {
	@include vi-calendar;
}

.vl-vi-calendar--after {
	@include vi-calendar-after;
}

// ### ICON: calendar_check
@mixin vi-calendar_check {
	&::before {
		content: "\f144";
	}
}

@mixin vi-calendar_check-after {
	&::after {
		content: "\f144";
	}
}

.vl-vi-calendar_check {
	@include vi-calendar_check;
}

.vl-vi-calendar_check--after {
	@include vi-calendar_check-after;
}

// ### ICON: calendar_date
@mixin vi-calendar_date {
	&::before {
		content: "\f145";
	}
}

@mixin vi-calendar_date-after {
	&::after {
		content: "\f145";
	}
}

.vl-vi-calendar_date {
	@include vi-calendar_date;
}

.vl-vi-calendar_date--after {
	@include vi-calendar_date-after;
}

// ### ICON: camera
@mixin vi-camera {
	&::before {
		content: "\f146";
	}
}

@mixin vi-camera-after {
	&::after {
		content: "\f146";
	}
}

.vl-vi-camera {
	@include vi-camera;
}

.vl-vi-camera--after {
	@include vi-camera-after;
}

// ### ICON: car
@mixin vi-car {
	&::before {
		content: "\f147";
	}
}

@mixin vi-car-after {
	&::after {
		content: "\f147";
	}
}

.vl-vi-car {
	@include vi-car;
}

.vl-vi-car--after {
	@include vi-car-after;
}

// ### ICON: chat-bubble-square-alt
@mixin vi-chat-bubble-square-alt {
	&::before {
		content: "\f148";
	}
}

@mixin vi-chat-bubble-square-alt-after {
	&::after {
		content: "\f148";
	}
}

.vl-vi-chat-bubble-square-alt {
	@include vi-chat-bubble-square-alt;
}

.vl-vi-chat-bubble-square-alt--after {
	@include vi-chat-bubble-square-alt-after;
}

// ### ICON: chat-bubble-square
@mixin vi-chat-bubble-square {
	&::before {
		content: "\f149";
	}
}

@mixin vi-chat-bubble-square-after {
	&::after {
		content: "\f149";
	}
}

.vl-vi-chat-bubble-square {
	@include vi-chat-bubble-square;
}

.vl-vi-chat-bubble-square--after {
	@include vi-chat-bubble-square-after;
}

// ### ICON: chat-help
@mixin vi-chat-help {
	&::before {
		content: "\f14a";
	}
}

@mixin vi-chat-help-after {
	&::after {
		content: "\f14a";
	}
}

.vl-vi-chat-help {
	@include vi-chat-help;
}

.vl-vi-chat-help--after {
	@include vi-chat-help-after;
}

// ### ICON: chat
@mixin vi-chat {
	&::before {
		content: "\f14b";
	}
}

@mixin vi-chat-after {
	&::after {
		content: "\f14b";
	}
}

.vl-vi-chat {
	@include vi-chat;
}

.vl-vi-chat--after {
	@include vi-chat-after;
}

// ### ICON: check-filled
@mixin vi-check-filled {
	&::before {
		content: "\f14c";
	}
}

@mixin vi-check-filled-after {
	&::after {
		content: "\f14c";
	}
}

.vl-vi-check-filled {
	@include vi-check-filled;
}

.vl-vi-check-filled--after {
	@include vi-check-filled-after;
}

// ### ICON: check-small
@mixin vi-check-small {
	&::before {
		content: "\f14d";
	}
}

@mixin vi-check-small-after {
	&::after {
		content: "\f14d";
	}
}

.vl-vi-check-small {
	@include vi-check-small;
}

.vl-vi-check-small--after {
	@include vi-check-small-after;
}

// ### ICON: check-thin
@mixin vi-check-thin {
	&::before {
		content: "\f14e";
	}
}

@mixin vi-check-thin-after {
	&::after {
		content: "\f14e";
	}
}

.vl-vi-check-thin {
	@include vi-check-thin;
}

.vl-vi-check-thin--after {
	@include vi-check-thin-after;
}

// ### ICON: check
@mixin vi-check {
	&::before {
		content: "\f14f";
	}
}

@mixin vi-check-after {
	&::after {
		content: "\f14f";
	}
}

.vl-vi-check {
	@include vi-check;
}

.vl-vi-check--after {
	@include vi-check-after;
}

// ### ICON: child
@mixin vi-child {
	&::before {
		content: "\f150";
	}
}

@mixin vi-child-after {
	&::after {
		content: "\f150";
	}
}

.vl-vi-child {
	@include vi-child;
}

.vl-vi-child--after {
	@include vi-child-after;
}

// ### ICON: clock
@mixin vi-clock {
	&::before {
		content: "\f151";
	}
}

@mixin vi-clock-after {
	&::after {
		content: "\f151";
	}
}

.vl-vi-clock {
	@include vi-clock;
}

.vl-vi-clock--after {
	@include vi-clock-after;
}

// ### ICON: close-light
@mixin vi-close-light {
	&::before {
		content: "\f152";
	}
}

@mixin vi-close-light-after {
	&::after {
		content: "\f152";
	}
}

.vl-vi-close-light {
	@include vi-close-light;
}

.vl-vi-close-light--after {
	@include vi-close-light-after;
}

// ### ICON: close-small
@mixin vi-close-small {
	&::before {
		content: "\f153";
	}
}

@mixin vi-close-small-after {
	&::after {
		content: "\f153";
	}
}

.vl-vi-close-small {
	@include vi-close-small;
}

.vl-vi-close-small--after {
	@include vi-close-small-after;
}

// ### ICON: close
@mixin vi-close {
	&::before {
		content: "\f154";
	}
}

@mixin vi-close-after {
	&::after {
		content: "\f154";
	}
}

.vl-vi-close {
	@include vi-close;
}

.vl-vi-close--after {
	@include vi-close-after;
}

// ### ICON: cloud-download
@mixin vi-cloud-download {
	&::before {
		content: "\f155";
	}
}

@mixin vi-cloud-download-after {
	&::after {
		content: "\f155";
	}
}

.vl-vi-cloud-download {
	@include vi-cloud-download;
}

.vl-vi-cloud-download--after {
	@include vi-cloud-download-after;
}

// ### ICON: cloud-upload
@mixin vi-cloud-upload {
	&::before {
		content: "\f156";
	}
}

@mixin vi-cloud-upload-after {
	&::after {
		content: "\f156";
	}
}

.vl-vi-cloud-upload {
	@include vi-cloud-upload;
}

.vl-vi-cloud-upload--after {
	@include vi-cloud-upload-after;
}

// ### ICON: cloud
@mixin vi-cloud {
	&::before {
		content: "\f157";
	}
}

@mixin vi-cloud-after {
	&::after {
		content: "\f157";
	}
}

.vl-vi-cloud {
	@include vi-cloud;
}

.vl-vi-cloud--after {
	@include vi-cloud-after;
}

// ### ICON: code-branch
@mixin vi-code-branch {
	&::before {
		content: "\f158";
	}
}

@mixin vi-code-branch-after {
	&::after {
		content: "\f158";
	}
}

.vl-vi-code-branch {
	@include vi-code-branch;
}

.vl-vi-code-branch--after {
	@include vi-code-branch-after;
}

// ### ICON: coffee-cup
@mixin vi-coffee-cup {
	&::before {
		content: "\f159";
	}
}

@mixin vi-coffee-cup-after {
	&::after {
		content: "\f159";
	}
}

.vl-vi-coffee-cup {
	@include vi-coffee-cup;
}

.vl-vi-coffee-cup--after {
	@include vi-coffee-cup-after;
}

// ### ICON: cog
@mixin vi-cog {
	&::before {
		content: "\f15a";
	}
}

@mixin vi-cog-after {
	&::after {
		content: "\f15a";
	}
}

.vl-vi-cog {
	@include vi-cog;
}

.vl-vi-cog--after {
	@include vi-cog-after;
}

// ### ICON: compass
@mixin vi-compass {
	&::before {
		content: "\f15b";
	}
}

@mixin vi-compass-after {
	&::after {
		content: "\f15b";
	}
}

.vl-vi-compass {
	@include vi-compass;
}

.vl-vi-compass--after {
	@include vi-compass-after;
}

// ### ICON: computer-screen
@mixin vi-computer-screen {
	&::before {
		content: "\f15c";
	}
}

@mixin vi-computer-screen-after {
	&::after {
		content: "\f15c";
	}
}

.vl-vi-computer-screen {
	@include vi-computer-screen;
}

.vl-vi-computer-screen--after {
	@include vi-computer-screen-after;
}

// ### ICON: confluence
@mixin vi-confluence {
	&::before {
		content: "\f15d";
	}
}

@mixin vi-confluence-after {
	&::after {
		content: "\f15d";
	}
}

.vl-vi-confluence {
	@include vi-confluence;
}

.vl-vi-confluence--after {
	@include vi-confluence-after;
}

// ### ICON: construction-crane
@mixin vi-construction-crane {
	&::before {
		content: "\f15e";
	}
}

@mixin vi-construction-crane-after {
	&::after {
		content: "\f15e";
	}
}

.vl-vi-construction-crane {
	@include vi-construction-crane;
}

.vl-vi-construction-crane--after {
	@include vi-construction-crane-after;
}

// ### ICON: construction-shack
@mixin vi-construction-shack {
	&::before {
		content: "\f15f";
	}
}

@mixin vi-construction-shack-after {
	&::after {
		content: "\f15f";
	}
}

.vl-vi-construction-shack {
	@include vi-construction-shack;
}

.vl-vi-construction-shack--after {
	@include vi-construction-shack-after;
}

// ### ICON: contacts
@mixin vi-contacts {
	&::before {
		content: "\f160";
	}
}

@mixin vi-contacts-after {
	&::after {
		content: "\f160";
	}
}

.vl-vi-contacts {
	@include vi-contacts;
}

.vl-vi-contacts--after {
	@include vi-contacts-after;
}

// ### ICON: content-book-favorite-star
@mixin vi-content-book-favorite-star {
	&::before {
		content: "\f161";
	}
}

@mixin vi-content-book-favorite-star-after {
	&::after {
		content: "\f161";
	}
}

.vl-vi-content-book-favorite-star {
	@include vi-content-book-favorite-star;
}

.vl-vi-content-book-favorite-star--after {
	@include vi-content-book-favorite-star-after;
}

// ### ICON: content-book
@mixin vi-content-book {
	&::before {
		content: "\f162";
	}
}

@mixin vi-content-book-after {
	&::after {
		content: "\f162";
	}
}

.vl-vi-content-book {
	@include vi-content-book;
}

.vl-vi-content-book--after {
	@include vi-content-book-after;
}

// ### ICON: content-box
@mixin vi-content-box {
	&::before {
		content: "\f163";
	}
}

@mixin vi-content-box-after {
	&::after {
		content: "\f163";
	}
}

.vl-vi-content-box {
	@include vi-content-box;
}

.vl-vi-content-box--after {
	@include vi-content-box-after;
}

// ### ICON: content-filter
@mixin vi-content-filter {
	&::before {
		content: "\f164";
	}
}

@mixin vi-content-filter-after {
	&::after {
		content: "\f164";
	}
}

.vl-vi-content-filter {
	@include vi-content-filter;
}

.vl-vi-content-filter--after {
	@include vi-content-filter-after;
}

// ### ICON: content-note
@mixin vi-content-note {
	&::before {
		content: "\f165";
	}
}

@mixin vi-content-note-after {
	&::after {
		content: "\f165";
	}
}

.vl-vi-content-note {
	@include vi-content-note;
}

.vl-vi-content-note--after {
	@include vi-content-note-after;
}

// ### ICON: content-view-column
@mixin vi-content-view-column {
	&::before {
		content: "\f166";
	}
}

@mixin vi-content-view-column-after {
	&::after {
		content: "\f166";
	}
}

.vl-vi-content-view-column {
	@include vi-content-view-column;
}

.vl-vi-content-view-column--after {
	@include vi-content-view-column-after;
}

// ### ICON: control-cross-over
@mixin vi-control-cross-over {
	&::before {
		content: "\f167";
	}
}

@mixin vi-control-cross-over-after {
	&::after {
		content: "\f167";
	}
}

.vl-vi-control-cross-over {
	@include vi-control-cross-over;
}

.vl-vi-control-cross-over--after {
	@include vi-control-cross-over-after;
}

// ### ICON: copy-paste
@mixin vi-copy-paste {
	&::before {
		content: "\f168";
	}
}

@mixin vi-copy-paste-after {
	&::after {
		content: "\f168";
	}
}

.vl-vi-copy-paste {
	@include vi-copy-paste;
}

.vl-vi-copy-paste--after {
	@include vi-copy-paste-after;
}

// ### ICON: copyright
@mixin vi-copyright {
	&::before {
		content: "\f169";
	}
}

@mixin vi-copyright-after {
	&::after {
		content: "\f169";
	}
}

.vl-vi-copyright {
	@include vi-copyright;
}

.vl-vi-copyright--after {
	@include vi-copyright-after;
}

// ### ICON: credit-card
@mixin vi-credit-card {
	&::before {
		content: "\f16a";
	}
}

@mixin vi-credit-card-after {
	&::after {
		content: "\f16a";
	}
}

.vl-vi-credit-card {
	@include vi-credit-card;
}

.vl-vi-credit-card--after {
	@include vi-credit-card-after;
}

// ### ICON: crop
@mixin vi-crop {
	&::before {
		content: "\f16b";
	}
}

@mixin vi-crop-after {
	&::after {
		content: "\f16b";
	}
}

.vl-vi-crop {
	@include vi-crop;
}

.vl-vi-crop--after {
	@include vi-crop-after;
}

// ### ICON: cross-thin
@mixin vi-cross-thin {
	&::before {
		content: "\f16c";
	}
}

@mixin vi-cross-thin-after {
	&::after {
		content: "\f16c";
	}
}

.vl-vi-cross-thin {
	@include vi-cross-thin;
}

.vl-vi-cross-thin--after {
	@include vi-cross-thin-after;
}

// ### ICON: cross
@mixin vi-cross {
	&::before {
		content: "\f16d";
	}
}

@mixin vi-cross-after {
	&::after {
		content: "\f16d";
	}
}

.vl-vi-cross {
	@include vi-cross;
}

.vl-vi-cross--after {
	@include vi-cross-after;
}

// ### ICON: cursor-arrow-big
@mixin vi-cursor-arrow-big {
	&::before {
		content: "\f16e";
	}
}

@mixin vi-cursor-arrow-big-after {
	&::after {
		content: "\f16e";
	}
}

.vl-vi-cursor-arrow-big {
	@include vi-cursor-arrow-big;
}

.vl-vi-cursor-arrow-big--after {
	@include vi-cursor-arrow-big-after;
}

// ### ICON: cursor-arrow-small
@mixin vi-cursor-arrow-small {
	&::before {
		content: "\f16f";
	}
}

@mixin vi-cursor-arrow-small-after {
	&::after {
		content: "\f16f";
	}
}

.vl-vi-cursor-arrow-small {
	@include vi-cursor-arrow-small;
}

.vl-vi-cursor-arrow-small--after {
	@include vi-cursor-arrow-small-after;
}

// ### ICON: cursor-finger-down
@mixin vi-cursor-finger-down {
	&::before {
		content: "\f170";
	}
}

@mixin vi-cursor-finger-down-after {
	&::after {
		content: "\f170";
	}
}

.vl-vi-cursor-finger-down {
	@include vi-cursor-finger-down;
}

.vl-vi-cursor-finger-down--after {
	@include vi-cursor-finger-down-after;
}

// ### ICON: cursor-finger-left
@mixin vi-cursor-finger-left {
	&::before {
		content: "\f171";
	}
}

@mixin vi-cursor-finger-left-after {
	&::after {
		content: "\f171";
	}
}

.vl-vi-cursor-finger-left {
	@include vi-cursor-finger-left;
}

.vl-vi-cursor-finger-left--after {
	@include vi-cursor-finger-left-after;
}

// ### ICON: cursor-finger-right
@mixin vi-cursor-finger-right {
	&::before {
		content: "\f172";
	}
}

@mixin vi-cursor-finger-right-after {
	&::after {
		content: "\f172";
	}
}

.vl-vi-cursor-finger-right {
	@include vi-cursor-finger-right;
}

.vl-vi-cursor-finger-right--after {
	@include vi-cursor-finger-right-after;
}

// ### ICON: cursor-finger-up
@mixin vi-cursor-finger-up {
	&::before {
		content: "\f173";
	}
}

@mixin vi-cursor-finger-up-after {
	&::after {
		content: "\f173";
	}
}

.vl-vi-cursor-finger-up {
	@include vi-cursor-finger-up;
}

.vl-vi-cursor-finger-up--after {
	@include vi-cursor-finger-up-after;
}

// ### ICON: cursor-hand
@mixin vi-cursor-hand {
	&::before {
		content: "\f174";
	}
}

@mixin vi-cursor-hand-after {
	&::after {
		content: "\f174";
	}
}

.vl-vi-cursor-hand {
	@include vi-cursor-hand;
}

.vl-vi-cursor-hand--after {
	@include vi-cursor-hand-after;
}

// ### ICON: cursor-hold
@mixin vi-cursor-hold {
	&::before {
		content: "\f175";
	}
}

@mixin vi-cursor-hold-after {
	&::after {
		content: "\f175";
	}
}

.vl-vi-cursor-hold {
	@include vi-cursor-hold;
}

.vl-vi-cursor-hold--after {
	@include vi-cursor-hold-after;
}

// ### ICON: demonstration
@mixin vi-demonstration {
	&::before {
		content: "\f176";
	}
}

@mixin vi-demonstration-after {
	&::after {
		content: "\f176";
	}
}

.vl-vi-demonstration {
	@include vi-demonstration;
}

.vl-vi-demonstration--after {
	@include vi-demonstration-after;
}

// ### ICON: diagram
@mixin vi-diagram {
	&::before {
		content: "\f177";
	}
}

@mixin vi-diagram-after {
	&::after {
		content: "\f177";
	}
}

.vl-vi-diagram {
	@include vi-diagram;
}

.vl-vi-diagram--after {
	@include vi-diagram-after;
}

// ### ICON: direction-sign
@mixin vi-direction-sign {
	&::before {
		content: "\f178";
	}
}

@mixin vi-direction-sign-after {
	&::after {
		content: "\f178";
	}
}

.vl-vi-direction-sign {
	@include vi-direction-sign;
}

.vl-vi-direction-sign--after {
	@include vi-direction-sign-after;
}

// ### ICON: document-small
@mixin vi-document-small {
	&::before {
		content: "\f179";
	}
}

@mixin vi-document-small-after {
	&::after {
		content: "\f179";
	}
}

.vl-vi-document-small {
	@include vi-document-small;
}

.vl-vi-document-small--after {
	@include vi-document-small-after;
}

// ### ICON: document
@mixin vi-document {
	&::before {
		content: "\f17a";
	}
}

@mixin vi-document-after {
	&::after {
		content: "\f17a";
	}
}

.vl-vi-document {
	@include vi-document;
}

.vl-vi-document--after {
	@include vi-document-after;
}

// ### ICON: double-arrow
@mixin vi-double-arrow {
	&::before {
		content: "\f17b";
	}
}

@mixin vi-double-arrow-after {
	&::after {
		content: "\f17b";
	}
}

.vl-vi-double-arrow {
	@include vi-double-arrow;
}

.vl-vi-double-arrow--after {
	@include vi-double-arrow-after;
}

// ### ICON: download-harddisk
@mixin vi-download-harddisk {
	&::before {
		content: "\f17c";
	}
}

@mixin vi-download-harddisk-after {
	&::after {
		content: "\f17c";
	}
}

.vl-vi-download-harddisk {
	@include vi-download-harddisk;
}

.vl-vi-download-harddisk--after {
	@include vi-download-harddisk-after;
}

// ### ICON: drawer-down
@mixin vi-drawer-down {
	&::before {
		content: "\f17d";
	}
}

@mixin vi-drawer-down-after {
	&::after {
		content: "\f17d";
	}
}

.vl-vi-drawer-down {
	@include vi-drawer-down;
}

.vl-vi-drawer-down--after {
	@include vi-drawer-down-after;
}

// ### ICON: drawer
@mixin vi-drawer {
	&::before {
		content: "\f17e";
	}
}

@mixin vi-drawer-after {
	&::after {
		content: "\f17e";
	}
}

.vl-vi-drawer {
	@include vi-drawer;
}

.vl-vi-drawer--after {
	@include vi-drawer-after;
}

// ### ICON: edit
@mixin vi-edit {
	&::before {
		content: "\f17f";
	}
}

@mixin vi-edit-after {
	&::after {
		content: "\f17f";
	}
}

.vl-vi-edit {
	@include vi-edit;
}

.vl-vi-edit--after {
	@include vi-edit-after;
}

// ### ICON: email-read
@mixin vi-email-read {
	&::before {
		content: "\f180";
	}
}

@mixin vi-email-read-after {
	&::after {
		content: "\f180";
	}
}

.vl-vi-email-read {
	@include vi-email-read;
}

.vl-vi-email-read--after {
	@include vi-email-read-after;
}

// ### ICON: email
@mixin vi-email {
	&::before {
		content: "\f181";
	}
}

@mixin vi-email-after {
	&::after {
		content: "\f181";
	}
}

.vl-vi-email {
	@include vi-email;
}

.vl-vi-email--after {
	@include vi-email-after;
}

// ### ICON: enlarge
@mixin vi-enlarge {
	&::before {
		content: "\f182";
	}
}

@mixin vi-enlarge-after {
	&::after {
		content: "\f182";
	}
}

.vl-vi-enlarge {
	@include vi-enlarge;
}

.vl-vi-enlarge--after {
	@include vi-enlarge-after;
}

// ### ICON: envelope
@mixin vi-envelope {
	&::before {
		content: "\f183";
	}
}

@mixin vi-envelope-after {
	&::after {
		content: "\f183";
	}
}

.vl-vi-envelope {
	@include vi-envelope;
}

.vl-vi-envelope--after {
	@include vi-envelope-after;
}

// ### ICON: expand-horizontal-alt
@mixin vi-expand-horizontal-alt {
	&::before {
		content: "\f184";
	}
}

@mixin vi-expand-horizontal-alt-after {
	&::after {
		content: "\f184";
	}
}

.vl-vi-expand-horizontal-alt {
	@include vi-expand-horizontal-alt;
}

.vl-vi-expand-horizontal-alt--after {
	@include vi-expand-horizontal-alt-after;
}

// ### ICON: expand-horizontal
@mixin vi-expand-horizontal {
	&::before {
		content: "\f185";
	}
}

@mixin vi-expand-horizontal-after {
	&::after {
		content: "\f185";
	}
}

.vl-vi-expand-horizontal {
	@include vi-expand-horizontal;
}

.vl-vi-expand-horizontal--after {
	@include vi-expand-horizontal-after;
}

// ### ICON: expand-vertical
@mixin vi-expand-vertical {
	&::before {
		content: "\f186";
	}
}

@mixin vi-expand-vertical-after {
	&::after {
		content: "\f186";
	}
}

.vl-vi-expand-vertical {
	@include vi-expand-vertical;
}

.vl-vi-expand-vertical--after {
	@include vi-expand-vertical-after;
}

// ### ICON: expand
@mixin vi-expand {
	&::before {
		content: "\f187";
	}
}

@mixin vi-expand-after {
	&::after {
		content: "\f187";
	}
}

.vl-vi-expand {
	@include vi-expand;
}

.vl-vi-expand--after {
	@include vi-expand-after;
}

// ### ICON: external
@mixin vi-external {
	&::before {
		content: "\f188";
	}
}

@mixin vi-external-after {
	&::after {
		content: "\f188";
	}
}

.vl-vi-external {
	@include vi-external;
}

.vl-vi-external--after {
	@include vi-external-after;
}

// ### ICON: facebook
@mixin vi-facebook {
	&::before {
		content: "\f189";
	}
}

@mixin vi-facebook-after {
	&::after {
		content: "\f189";
	}
}

.vl-vi-facebook {
	@include vi-facebook;
}

.vl-vi-facebook--after {
	@include vi-facebook-after;
}

// ### ICON: faq
@mixin vi-faq {
	&::before {
		content: "\f18a";
	}
}

@mixin vi-faq-after {
	&::after {
		content: "\f18a";
	}
}

.vl-vi-faq {
	@include vi-faq;
}

.vl-vi-faq--after {
	@include vi-faq-after;
}

// ### ICON: fastback
@mixin vi-fastback {
	&::before {
		content: "\f18b";
	}
}

@mixin vi-fastback-after {
	&::after {
		content: "\f18b";
	}
}

.vl-vi-fastback {
	@include vi-fastback;
}

.vl-vi-fastback--after {
	@include vi-fastback-after;
}

// ### ICON: fastforward
@mixin vi-fastforward {
	&::before {
		content: "\f18c";
	}
}

@mixin vi-fastforward-after {
	&::after {
		content: "\f18c";
	}
}

.vl-vi-fastforward {
	@include vi-fastforward;
}

.vl-vi-fastforward--after {
	@include vi-fastforward-after;
}

// ### ICON: file-audio
@mixin vi-file-audio {
	&::before {
		content: "\f18d";
	}
}

@mixin vi-file-audio-after {
	&::after {
		content: "\f18d";
	}
}

.vl-vi-file-audio {
	@include vi-file-audio;
}

.vl-vi-file-audio--after {
	@include vi-file-audio-after;
}

// ### ICON: file-copy
@mixin vi-file-copy {
	&::before {
		content: "\f18e";
	}
}

@mixin vi-file-copy-after {
	&::after {
		content: "\f18e";
	}
}

.vl-vi-file-copy {
	@include vi-file-copy;
}

.vl-vi-file-copy--after {
	@include vi-file-copy-after;
}

// ### ICON: file-download
@mixin vi-file-download {
	&::before {
		content: "\f18f";
	}
}

@mixin vi-file-download-after {
	&::after {
		content: "\f18f";
	}
}

.vl-vi-file-download {
	@include vi-file-download;
}

.vl-vi-file-download--after {
	@include vi-file-download-after;
}

// ### ICON: file-image
@mixin vi-file-image {
	&::before {
		content: "\f190";
	}
}

@mixin vi-file-image-after {
	&::after {
		content: "\f190";
	}
}

.vl-vi-file-image {
	@include vi-file-image;
}

.vl-vi-file-image--after {
	@include vi-file-image-after;
}

// ### ICON: file-office-doc
@mixin vi-file-office-doc {
	&::before {
		content: "\f191";
	}
}

@mixin vi-file-office-doc-after {
	&::after {
		content: "\f191";
	}
}

.vl-vi-file-office-doc {
	@include vi-file-office-doc;
}

.vl-vi-file-office-doc--after {
	@include vi-file-office-doc-after;
}

// ### ICON: file-office-pdf
@mixin vi-file-office-pdf {
	&::before {
		content: "\f192";
	}
}

@mixin vi-file-office-pdf-after {
	&::after {
		content: "\f192";
	}
}

.vl-vi-file-office-pdf {
	@include vi-file-office-pdf;
}

.vl-vi-file-office-pdf--after {
	@include vi-file-office-pdf-after;
}

// ### ICON: file-office-ppt
@mixin vi-file-office-ppt {
	&::before {
		content: "\f193";
	}
}

@mixin vi-file-office-ppt-after {
	&::after {
		content: "\f193";
	}
}

.vl-vi-file-office-ppt {
	@include vi-file-office-ppt;
}

.vl-vi-file-office-ppt--after {
	@include vi-file-office-ppt-after;
}

// ### ICON: file-office-xls
@mixin vi-file-office-xls {
	&::before {
		content: "\f194";
	}
}

@mixin vi-file-office-xls-after {
	&::after {
		content: "\f194";
	}
}

.vl-vi-file-office-xls {
	@include vi-file-office-xls;
}

.vl-vi-file-office-xls--after {
	@include vi-file-office-xls-after;
}

// ### ICON: file-swap
@mixin vi-file-swap {
	&::before {
		content: "\f195";
	}
}

@mixin vi-file-swap-after {
	&::after {
		content: "\f195";
	}
}

.vl-vi-file-swap {
	@include vi-file-swap;
}

.vl-vi-file-swap--after {
	@include vi-file-swap-after;
}

// ### ICON: file-tasks-check
@mixin vi-file-tasks-check {
	&::before {
		content: "\f196";
	}
}

@mixin vi-file-tasks-check-after {
	&::after {
		content: "\f196";
	}
}

.vl-vi-file-tasks-check {
	@include vi-file-tasks-check;
}

.vl-vi-file-tasks-check--after {
	@include vi-file-tasks-check-after;
}

// ### ICON: file-video
@mixin vi-file-video {
	&::before {
		content: "\f197";
	}
}

@mixin vi-file-video-after {
	&::after {
		content: "\f197";
	}
}

.vl-vi-file-video {
	@include vi-file-video;
}

.vl-vi-file-video--after {
	@include vi-file-video-after;
}

// ### ICON: file-zipped-new
@mixin vi-file-zipped-new {
	&::before {
		content: "\f198";
	}
}

@mixin vi-file-zipped-new-after {
	&::after {
		content: "\f198";
	}
}

.vl-vi-file-zipped-new {
	@include vi-file-zipped-new;
}

.vl-vi-file-zipped-new--after {
	@include vi-file-zipped-new-after;
}

// ### ICON: file-zipped-vice
@mixin vi-file-zipped-vice {
	&::before {
		content: "\f199";
	}
}

@mixin vi-file-zipped-vice-after {
	&::after {
		content: "\f199";
	}
}

.vl-vi-file-zipped-vice {
	@include vi-file-zipped-vice;
}

.vl-vi-file-zipped-vice--after {
	@include vi-file-zipped-vice-after;
}

// ### ICON: file
@mixin vi-file {
	&::before {
		content: "\f19a";
	}
}

@mixin vi-file-after {
	&::after {
		content: "\f19a";
	}
}

.vl-vi-file {
	@include vi-file;
}

.vl-vi-file--after {
	@include vi-file-after;
}

// ### ICON: files-coding
@mixin vi-files-coding {
	&::before {
		content: "\f19b";
	}
}

@mixin vi-files-coding-after {
	&::after {
		content: "\f19b";
	}
}

.vl-vi-files-coding {
	@include vi-files-coding;
}

.vl-vi-files-coding--after {
	@include vi-files-coding-after;
}

// ### ICON: film
@mixin vi-film {
	&::before {
		content: "\f19c";
	}
}

@mixin vi-film-after {
	&::after {
		content: "\f19c";
	}
}

.vl-vi-film {
	@include vi-film;
}

.vl-vi-film--after {
	@include vi-film-after;
}

// ### ICON: flickr
@mixin vi-flickr {
	&::before {
		content: "\f19d";
	}
}

@mixin vi-flickr-after {
	&::after {
		content: "\f19d";
	}
}

.vl-vi-flickr {
	@include vi-flickr;
}

.vl-vi-flickr--after {
	@include vi-flickr-after;
}

// ### ICON: focus
@mixin vi-focus {
	&::before {
		content: "\f19e";
	}
}

@mixin vi-focus-after {
	&::after {
		content: "\f19e";
	}
}

.vl-vi-focus {
	@include vi-focus;
}

.vl-vi-focus--after {
	@include vi-focus-after;
}

// ### ICON: folder
@mixin vi-folder {
	&::before {
		content: "\f19f";
	}
}

@mixin vi-folder-after {
	&::after {
		content: "\f19f";
	}
}

.vl-vi-folder {
	@include vi-folder;
}

.vl-vi-folder--after {
	@include vi-folder-after;
}

// ### ICON: font
@mixin vi-font {
	&::before {
		content: "\f1a0";
	}
}

@mixin vi-font-after {
	&::after {
		content: "\f1a0";
	}
}

.vl-vi-font {
	@include vi-font;
}

.vl-vi-font--after {
	@include vi-font-after;
}

// ### ICON: gender-female-male
@mixin vi-gender-female-male {
	&::before {
		content: "\f1a1";
	}
}

@mixin vi-gender-female-male-after {
	&::after {
		content: "\f1a1";
	}
}

.vl-vi-gender-female-male {
	@include vi-gender-female-male;
}

.vl-vi-gender-female-male--after {
	@include vi-gender-female-male-after;
}

// ### ICON: gender-female
@mixin vi-gender-female {
	&::before {
		content: "\f1a2";
	}
}

@mixin vi-gender-female-after {
	&::after {
		content: "\f1a2";
	}
}

.vl-vi-gender-female {
	@include vi-gender-female;
}

.vl-vi-gender-female--after {
	@include vi-gender-female-after;
}

// ### ICON: gender-male
@mixin vi-gender-male {
	&::before {
		content: "\f1a3";
	}
}

@mixin vi-gender-male-after {
	&::after {
		content: "\f1a3";
	}
}

.vl-vi-gender-male {
	@include vi-gender-male;
}

.vl-vi-gender-male--after {
	@include vi-gender-male-after;
}

// ### ICON: gender-transgender
@mixin vi-gender-transgender {
	&::before {
		content: "\f1a4";
	}
}

@mixin vi-gender-transgender-after {
	&::after {
		content: "\f1a4";
	}
}

.vl-vi-gender-transgender {
	@include vi-gender-transgender;
}

.vl-vi-gender-transgender--after {
	@include vi-gender-transgender-after;
}

// ### ICON: globe
@mixin vi-globe {
	&::before {
		content: "\f1a5";
	}
}

@mixin vi-globe-after {
	&::after {
		content: "\f1a5";
	}
}

.vl-vi-globe {
	@include vi-globe;
}

.vl-vi-globe--after {
	@include vi-globe-after;
}

// ### ICON: googleplus
@mixin vi-googleplus {
	&::before {
		content: "\f1a6";
	}
}

@mixin vi-googleplus-after {
	&::after {
		content: "\f1a6";
	}
}

.vl-vi-googleplus {
	@include vi-googleplus;
}

.vl-vi-googleplus--after {
	@include vi-googleplus-after;
}

// ### ICON: graduate
@mixin vi-graduate {
	&::before {
		content: "\f1a7";
	}
}

@mixin vi-graduate-after {
	&::after {
		content: "\f1a7";
	}
}

.vl-vi-graduate {
	@include vi-graduate;
}

.vl-vi-graduate--after {
	@include vi-graduate-after;
}

// ### ICON: graduation-hat
@mixin vi-graduation-hat {
	&::before {
		content: "\f1a8";
	}
}

@mixin vi-graduation-hat-after {
	&::after {
		content: "\f1a8";
	}
}

.vl-vi-graduation-hat {
	@include vi-graduation-hat;
}

.vl-vi-graduation-hat--after {
	@include vi-graduation-hat-after;
}

// ### ICON: harddisk
@mixin vi-harddisk {
	&::before {
		content: "\f1a9";
	}
}

@mixin vi-harddisk-after {
	&::after {
		content: "\f1a9";
	}
}

.vl-vi-harddisk {
	@include vi-harddisk;
}

.vl-vi-harddisk--after {
	@include vi-harddisk-after;
}

// ### ICON: headphone
@mixin vi-headphone {
	&::before {
		content: "\f1aa";
	}
}

@mixin vi-headphone-after {
	&::after {
		content: "\f1aa";
	}
}

.vl-vi-headphone {
	@include vi-headphone;
}

.vl-vi-headphone--after {
	@include vi-headphone-after;
}

// ### ICON: health-first-aid-kit
@mixin vi-health-first-aid-kit {
	&::before {
		content: "\f1ab";
	}
}

@mixin vi-health-first-aid-kit-after {
	&::after {
		content: "\f1ab";
	}
}

.vl-vi-health-first-aid-kit {
	@include vi-health-first-aid-kit;
}

.vl-vi-health-first-aid-kit--after {
	@include vi-health-first-aid-kit-after;
}

// ### ICON: health-heart-pulse
@mixin vi-health-heart-pulse {
	&::before {
		content: "\f1ac";
	}
}

@mixin vi-health-heart-pulse-after {
	&::after {
		content: "\f1ac";
	}
}

.vl-vi-health-heart-pulse {
	@include vi-health-heart-pulse;
}

.vl-vi-health-heart-pulse--after {
	@include vi-health-heart-pulse-after;
}

// ### ICON: health-hospital
@mixin vi-health-hospital {
	&::before {
		content: "\f1ad";
	}
}

@mixin vi-health-hospital-after {
	&::after {
		content: "\f1ad";
	}
}

.vl-vi-health-hospital {
	@include vi-health-hospital;
}

.vl-vi-health-hospital--after {
	@include vi-health-hospital-after;
}

// ### ICON: hide
@mixin vi-hide {
	&::before {
		content: "\f1ae";
	}
}

@mixin vi-hide-after {
	&::after {
		content: "\f1ae";
	}
}

.vl-vi-hide {
	@include vi-hide;
}

.vl-vi-hide--after {
	@include vi-hide-after;
}

// ### ICON: hierarchy
@mixin vi-hierarchy {
	&::before {
		content: "\f1af";
	}
}

@mixin vi-hierarchy-after {
	&::after {
		content: "\f1af";
	}
}

.vl-vi-hierarchy {
	@include vi-hierarchy;
}

.vl-vi-hierarchy--after {
	@include vi-hierarchy-after;
}

// ### ICON: hotel-bath-shower
@mixin vi-hotel-bath-shower {
	&::before {
		content: "\f1b0";
	}
}

@mixin vi-hotel-bath-shower-after {
	&::after {
		content: "\f1b0";
	}
}

.vl-vi-hotel-bath-shower {
	@include vi-hotel-bath-shower;
}

.vl-vi-hotel-bath-shower--after {
	@include vi-hotel-bath-shower-after;
}

// ### ICON: hotel-bed
@mixin vi-hotel-bed {
	&::before {
		content: "\f1b1";
	}
}

@mixin vi-hotel-bed-after {
	&::after {
		content: "\f1b1";
	}
}

.vl-vi-hotel-bed {
	@include vi-hotel-bed;
}

.vl-vi-hotel-bed--after {
	@include vi-hotel-bed-after;
}

// ### ICON: hotel-fire-alarm
@mixin vi-hotel-fire-alarm {
	&::before {
		content: "\f1b2";
	}
}

@mixin vi-hotel-fire-alarm-after {
	&::after {
		content: "\f1b2";
	}
}

.vl-vi-hotel-fire-alarm {
	@include vi-hotel-fire-alarm;
}

.vl-vi-hotel-fire-alarm--after {
	@include vi-hotel-fire-alarm-after;
}

// ### ICON: hotel-shower
@mixin vi-hotel-shower {
	&::before {
		content: "\f1b3";
	}
}

@mixin vi-hotel-shower-after {
	&::after {
		content: "\f1b3";
	}
}

.vl-vi-hotel-shower {
	@include vi-hotel-shower;
}

.vl-vi-hotel-shower--after {
	@include vi-hotel-shower-after;
}

// ### ICON: id-card
@mixin vi-id-card {
	&::before {
		content: "\f1b4";
	}
}

@mixin vi-id-card-after {
	&::after {
		content: "\f1b4";
	}
}

.vl-vi-id-card {
	@include vi-id-card;
}

.vl-vi-id-card--after {
	@include vi-id-card-after;
}

// ### ICON: id
@mixin vi-id {
	&::before {
		content: "\f1b5";
	}
}

@mixin vi-id-after {
	&::after {
		content: "\f1b5";
	}
}

.vl-vi-id {
	@include vi-id;
}

.vl-vi-id--after {
	@include vi-id-after;
}

// ### ICON: images-copy
@mixin vi-images-copy {
	&::before {
		content: "\f1b6";
	}
}

@mixin vi-images-copy-after {
	&::after {
		content: "\f1b6";
	}
}

.vl-vi-images-copy {
	@include vi-images-copy;
}

.vl-vi-images-copy--after {
	@include vi-images-copy-after;
}

// ### ICON: images
@mixin vi-images {
	&::before {
		content: "\f1b7";
	}
}

@mixin vi-images-after {
	&::after {
		content: "\f1b7";
	}
}

.vl-vi-images {
	@include vi-images;
}

.vl-vi-images--after {
	@include vi-images-after;
}

// ### ICON: inbox
@mixin vi-inbox {
	&::before {
		content: "\f1b8";
	}
}

@mixin vi-inbox-after {
	&::after {
		content: "\f1b8";
	}
}

.vl-vi-inbox {
	@include vi-inbox;
}

.vl-vi-inbox--after {
	@include vi-inbox-after;
}

// ### ICON: indent-left
@mixin vi-indent-left {
	&::before {
		content: "\f1b9";
	}
}

@mixin vi-indent-left-after {
	&::after {
		content: "\f1b9";
	}
}

.vl-vi-indent-left {
	@include vi-indent-left;
}

.vl-vi-indent-left--after {
	@include vi-indent-left-after;
}

// ### ICON: indent-right
@mixin vi-indent-right {
	&::before {
		content: "\f1ba";
	}
}

@mixin vi-indent-right-after {
	&::after {
		content: "\f1ba";
	}
}

.vl-vi-indent-right {
	@include vi-indent-right;
}

.vl-vi-indent-right--after {
	@include vi-indent-right-after;
}

// ### ICON: info-filled
@mixin vi-info-filled {
	&::before {
		content: "\f1bb";
	}
}

@mixin vi-info-filled-after {
	&::after {
		content: "\f1bb";
	}
}

.vl-vi-info-filled {
	@include vi-info-filled;
}

.vl-vi-info-filled--after {
	@include vi-info-filled-after;
}

// ### ICON: info-small
@mixin vi-info-small {
	&::before {
		content: "\f1bc";
	}
}

@mixin vi-info-small-after {
	&::after {
		content: "\f1bc";
	}
}

.vl-vi-info-small {
	@include vi-info-small;
}

.vl-vi-info-small--after {
	@include vi-info-small-after;
}

// ### ICON: info
@mixin vi-info {
	&::before {
		content: "\f1bd";
	}
}

@mixin vi-info-after {
	&::after {
		content: "\f1bd";
	}
}

.vl-vi-info {
	@include vi-info;
}

.vl-vi-info--after {
	@include vi-info-after;
}

// ### ICON: instagram
@mixin vi-instagram {
	&::before {
		content: "\f1be";
	}
}

@mixin vi-instagram-after {
	&::after {
		content: "\f1be";
	}
}

.vl-vi-instagram {
	@include vi-instagram;
}

.vl-vi-instagram--after {
	@include vi-instagram-after;
}

// ### ICON: italic
@mixin vi-italic {
	&::before {
		content: "\f1bf";
	}
}

@mixin vi-italic-after {
	&::after {
		content: "\f1bf";
	}
}

.vl-vi-italic {
	@include vi-italic;
}

.vl-vi-italic--after {
	@include vi-italic-after;
}

// ### ICON: jira
@mixin vi-jira {
	&::before {
		content: "\f1c0";
	}
}

@mixin vi-jira-after {
	&::after {
		content: "\f1c0";
	}
}

.vl-vi-jira {
	@include vi-jira;
}

.vl-vi-jira--after {
	@include vi-jira-after;
}

// ### ICON: key
@mixin vi-key {
	&::before {
		content: "\f1c1";
	}
}

@mixin vi-key-after {
	&::after {
		content: "\f1c1";
	}
}

.vl-vi-key {
	@include vi-key;
}

.vl-vi-key--after {
	@include vi-key-after;
}

// ### ICON: keyboard
@mixin vi-keyboard {
	&::before {
		content: "\f1c2";
	}
}

@mixin vi-keyboard-after {
	&::after {
		content: "\f1c2";
	}
}

.vl-vi-keyboard {
	@include vi-keyboard;
}

.vl-vi-keyboard--after {
	@include vi-keyboard-after;
}

// ### ICON: laptop
@mixin vi-laptop {
	&::before {
		content: "\f1c3";
	}
}

@mixin vi-laptop-after {
	&::after {
		content: "\f1c3";
	}
}

.vl-vi-laptop {
	@include vi-laptop;
}

.vl-vi-laptop--after {
	@include vi-laptop-after;
}

// ### ICON: lightbulb
@mixin vi-lightbulb {
	&::before {
		content: "\f1c4";
	}
}

@mixin vi-lightbulb-after {
	&::after {
		content: "\f1c4";
	}
}

.vl-vi-lightbulb {
	@include vi-lightbulb;
}

.vl-vi-lightbulb--after {
	@include vi-lightbulb-after;
}

// ### ICON: link-broken
@mixin vi-link-broken {
	&::before {
		content: "\f1c5";
	}
}

@mixin vi-link-broken-after {
	&::after {
		content: "\f1c5";
	}
}

.vl-vi-link-broken {
	@include vi-link-broken;
}

.vl-vi-link-broken--after {
	@include vi-link-broken-after;
}

// ### ICON: link
@mixin vi-link {
	&::before {
		content: "\f1c6";
	}
}

@mixin vi-link-after {
	&::after {
		content: "\f1c6";
	}
}

.vl-vi-link {
	@include vi-link;
}

.vl-vi-link--after {
	@include vi-link-after;
}

// ### ICON: linkedin
@mixin vi-linkedin {
	&::before {
		content: "\f1c7";
	}
}

@mixin vi-linkedin-after {
	&::after {
		content: "\f1c7";
	}
}

.vl-vi-linkedin {
	@include vi-linkedin;
}

.vl-vi-linkedin--after {
	@include vi-linkedin-after;
}

// ### ICON: list-bullets-alt
@mixin vi-list-bullets-alt {
	&::before {
		content: "\f1c8";
	}
}

@mixin vi-list-bullets-alt-after {
	&::after {
		content: "\f1c8";
	}
}

.vl-vi-list-bullets-alt {
	@include vi-list-bullets-alt;
}

.vl-vi-list-bullets-alt--after {
	@include vi-list-bullets-alt-after;
}

// ### ICON: list-bullets
@mixin vi-list-bullets {
	&::before {
		content: "\f1c9";
	}
}

@mixin vi-list-bullets-after {
	&::after {
		content: "\f1c9";
	}
}

.vl-vi-list-bullets {
	@include vi-list-bullets;
}

.vl-vi-list-bullets--after {
	@include vi-list-bullets-after;
}

// ### ICON: list-numbers
@mixin vi-list-numbers {
	&::before {
		content: "\f1ca";
	}
}

@mixin vi-list-numbers-after {
	&::after {
		content: "\f1ca";
	}
}

.vl-vi-list-numbers {
	@include vi-list-numbers;
}

.vl-vi-list-numbers--after {
	@include vi-list-numbers-after;
}

// ### ICON: list
@mixin vi-list {
	&::before {
		content: "\f1cb";
	}
}

@mixin vi-list-after {
	&::after {
		content: "\f1cb";
	}
}

.vl-vi-list {
	@include vi-list;
}

.vl-vi-list--after {
	@include vi-list-after;
}

// ### ICON: location-direction-arrow
@mixin vi-location-direction-arrow {
	&::before {
		content: "\f1cc";
	}
}

@mixin vi-location-direction-arrow-after {
	&::after {
		content: "\f1cc";
	}
}

.vl-vi-location-direction-arrow {
	@include vi-location-direction-arrow;
}

.vl-vi-location-direction-arrow--after {
	@include vi-location-direction-arrow-after;
}

// ### ICON: location-gps
@mixin vi-location-gps {
	&::before {
		content: "\f1cd";
	}
}

@mixin vi-location-gps-after {
	&::after {
		content: "\f1cd";
	}
}

.vl-vi-location-gps {
	@include vi-location-gps;
}

.vl-vi-location-gps--after {
	@include vi-location-gps-after;
}

// ### ICON: location-map
@mixin vi-location-map {
	&::before {
		content: "\f1ce";
	}
}

@mixin vi-location-map-after {
	&::after {
		content: "\f1ce";
	}
}

.vl-vi-location-map {
	@include vi-location-map;
}

.vl-vi-location-map--after {
	@include vi-location-map-after;
}

// ### ICON: location
@mixin vi-location {
	&::before {
		content: "\f1cf";
	}
}

@mixin vi-location-after {
	&::after {
		content: "\f1cf";
	}
}

.vl-vi-location {
	@include vi-location;
}

.vl-vi-location--after {
	@include vi-location-after;
}

// ### ICON: lock-unlock
@mixin vi-lock-unlock {
	&::before {
		content: "\f1d0";
	}
}

@mixin vi-lock-unlock-after {
	&::after {
		content: "\f1d0";
	}
}

.vl-vi-lock-unlock {
	@include vi-lock-unlock;
}

.vl-vi-lock-unlock--after {
	@include vi-lock-unlock-after;
}

// ### ICON: lock
@mixin vi-lock {
	&::before {
		content: "\f1d1";
	}
}

@mixin vi-lock-after {
	&::after {
		content: "\f1d1";
	}
}

.vl-vi-lock {
	@include vi-lock;
}

.vl-vi-lock--after {
	@include vi-lock-after;
}

// ### ICON: login
@mixin vi-login {
	&::before {
		content: "\f1d2";
	}
}

@mixin vi-login-after {
	&::after {
		content: "\f1d2";
	}
}

.vl-vi-login {
	@include vi-login;
}

.vl-vi-login--after {
	@include vi-login-after;
}

// ### ICON: logout
@mixin vi-logout {
	&::before {
		content: "\f1d3";
	}
}

@mixin vi-logout-after {
	&::after {
		content: "\f1d3";
	}
}

.vl-vi-logout {
	@include vi-logout;
}

.vl-vi-logout--after {
	@include vi-logout-after;
}

// ### ICON: long-arrow
@mixin vi-long-arrow {
	&::before {
		content: "\f1d4";
	}
}

@mixin vi-long-arrow-after {
	&::after {
		content: "\f1d4";
	}
}

.vl-vi-long-arrow {
	@include vi-long-arrow;
}

.vl-vi-long-arrow--after {
	@include vi-long-arrow-after;
}

// ### ICON: magnifier
@mixin vi-magnifier {
	&::before {
		content: "\f1d5";
	}
}

@mixin vi-magnifier-after {
	&::after {
		content: "\f1d5";
	}
}

.vl-vi-magnifier {
	@include vi-magnifier;
}

.vl-vi-magnifier--after {
	@include vi-magnifier-after;
}

// ### ICON: mail
@mixin vi-mail {
	&::before {
		content: "\f1d6";
	}
}

@mixin vi-mail-after {
	&::after {
		content: "\f1d6";
	}
}

.vl-vi-mail {
	@include vi-mail;
}

.vl-vi-mail--after {
	@include vi-mail-after;
}

// ### ICON: market
@mixin vi-market {
	&::before {
		content: "\f1d7";
	}
}

@mixin vi-market-after {
	&::after {
		content: "\f1d7";
	}
}

.vl-vi-market {
	@include vi-market;
}

.vl-vi-market--after {
	@include vi-market-after;
}

// ### ICON: menu
@mixin vi-menu {
	&::before {
		content: "\f1d8";
	}
}

@mixin vi-menu-after {
	&::after {
		content: "\f1d8";
	}
}

.vl-vi-menu {
	@include vi-menu;
}

.vl-vi-menu--after {
	@include vi-menu-after;
}

// ### ICON: messenger
@mixin vi-messenger {
	&::before {
		content: "\f1d9";
	}
}

@mixin vi-messenger-after {
	&::after {
		content: "\f1d9";
	}
}

.vl-vi-messenger {
	@include vi-messenger;
}

.vl-vi-messenger--after {
	@include vi-messenger-after;
}

// ### ICON: microphone-off
@mixin vi-microphone-off {
	&::before {
		content: "\f1da";
	}
}

@mixin vi-microphone-off-after {
	&::after {
		content: "\f1da";
	}
}

.vl-vi-microphone-off {
	@include vi-microphone-off;
}

.vl-vi-microphone-off--after {
	@include vi-microphone-off-after;
}

// ### ICON: microphone
@mixin vi-microphone {
	&::before {
		content: "\f1db";
	}
}

@mixin vi-microphone-after {
	&::after {
		content: "\f1db";
	}
}

.vl-vi-microphone {
	@include vi-microphone;
}

.vl-vi-microphone--after {
	@include vi-microphone-after;
}

// ### ICON: minus-circle
@mixin vi-minus-circle {
	&::before {
		content: "\f1dc";
	}
}

@mixin vi-minus-circle-after {
	&::after {
		content: "\f1dc";
	}
}

.vl-vi-minus-circle {
	@include vi-minus-circle;
}

.vl-vi-minus-circle--after {
	@include vi-minus-circle-after;
}

// ### ICON: minus
@mixin vi-minus {
	&::before {
		content: "\f1dd";
	}
}

@mixin vi-minus-after {
	&::after {
		content: "\f1dd";
	}
}

.vl-vi-minus {
	@include vi-minus;
}

.vl-vi-minus--after {
	@include vi-minus-after;
}

// ### ICON: mobile-phone
@mixin vi-mobile-phone {
	&::before {
		content: "\f1de";
	}
}

@mixin vi-mobile-phone-after {
	&::after {
		content: "\f1de";
	}
}

.vl-vi-mobile-phone {
	@include vi-mobile-phone;
}

.vl-vi-mobile-phone--after {
	@include vi-mobile-phone-after;
}

// ### ICON: move-down
@mixin vi-move-down {
	&::before {
		content: "\f1df";
	}
}

@mixin vi-move-down-after {
	&::after {
		content: "\f1df";
	}
}

.vl-vi-move-down {
	@include vi-move-down;
}

.vl-vi-move-down--after {
	@include vi-move-down-after;
}

// ### ICON: move-left-right
@mixin vi-move-left-right {
	&::before {
		content: "\f1e0";
	}
}

@mixin vi-move-left-right-after {
	&::after {
		content: "\f1e0";
	}
}

.vl-vi-move-left-right {
	@include vi-move-left-right;
}

.vl-vi-move-left-right--after {
	@include vi-move-left-right-after;
}

// ### ICON: moving-elevator
@mixin vi-moving-elevator {
	&::before {
		content: "\f1e1";
	}
}

@mixin vi-moving-elevator-after {
	&::after {
		content: "\f1e1";
	}
}

.vl-vi-moving-elevator {
	@include vi-moving-elevator;
}

.vl-vi-moving-elevator--after {
	@include vi-moving-elevator-after;
}

// ### ICON: music-note
@mixin vi-music-note {
	&::before {
		content: "\f1e2";
	}
}

@mixin vi-music-note-after {
	&::after {
		content: "\f1e2";
	}
}

.vl-vi-music-note {
	@include vi-music-note;
}

.vl-vi-music-note--after {
	@include vi-music-note-after;
}

// ### ICON: nature-leaf
@mixin vi-nature-leaf {
	&::before {
		content: "\f1e3";
	}
}

@mixin vi-nature-leaf-after {
	&::after {
		content: "\f1e3";
	}
}

.vl-vi-nature-leaf {
	@include vi-nature-leaf;
}

.vl-vi-nature-leaf--after {
	@include vi-nature-leaf-after;
}

// ### ICON: nature-tree
@mixin vi-nature-tree {
	&::before {
		content: "\f1e4";
	}
}

@mixin vi-nature-tree-after {
	&::after {
		content: "\f1e4";
	}
}

.vl-vi-nature-tree {
	@include vi-nature-tree;
}

.vl-vi-nature-tree--after {
	@include vi-nature-tree-after;
}

// ### ICON: nav-down-double
@mixin vi-nav-down-double {
	&::before {
		content: "\f1e5";
	}
}

@mixin vi-nav-down-double-after {
	&::after {
		content: "\f1e5";
	}
}

.vl-vi-nav-down-double {
	@include vi-nav-down-double;
}

.vl-vi-nav-down-double--after {
	@include vi-nav-down-double-after;
}

// ### ICON: nav-down-light
@mixin vi-nav-down-light {
	&::before {
		content: "\f1e6";
	}
}

@mixin vi-nav-down-light-after {
	&::after {
		content: "\f1e6";
	}
}

.vl-vi-nav-down-light {
	@include vi-nav-down-light;
}

.vl-vi-nav-down-light--after {
	@include vi-nav-down-light-after;
}

// ### ICON: nav-down
@mixin vi-nav-down {
	&::before {
		content: "\f1e7";
	}
}

@mixin vi-nav-down-after {
	&::after {
		content: "\f1e7";
	}
}

.vl-vi-nav-down {
	@include vi-nav-down;
}

.vl-vi-nav-down--after {
	@include vi-nav-down-after;
}

// ### ICON: nav-left-double
@mixin vi-nav-left-double {
	&::before {
		content: "\f1e8";
	}
}

@mixin vi-nav-left-double-after {
	&::after {
		content: "\f1e8";
	}
}

.vl-vi-nav-left-double {
	@include vi-nav-left-double;
}

.vl-vi-nav-left-double--after {
	@include vi-nav-left-double-after;
}

// ### ICON: nav-left-light
@mixin vi-nav-left-light {
	&::before {
		content: "\f1e9";
	}
}

@mixin vi-nav-left-light-after {
	&::after {
		content: "\f1e9";
	}
}

.vl-vi-nav-left-light {
	@include vi-nav-left-light;
}

.vl-vi-nav-left-light--after {
	@include vi-nav-left-light-after;
}

// ### ICON: nav-left
@mixin vi-nav-left {
	&::before {
		content: "\f1ea";
	}
}

@mixin vi-nav-left-after {
	&::after {
		content: "\f1ea";
	}
}

.vl-vi-nav-left {
	@include vi-nav-left;
}

.vl-vi-nav-left--after {
	@include vi-nav-left-after;
}

// ### ICON: nav-right-double
@mixin vi-nav-right-double {
	&::before {
		content: "\f1eb";
	}
}

@mixin vi-nav-right-double-after {
	&::after {
		content: "\f1eb";
	}
}

.vl-vi-nav-right-double {
	@include vi-nav-right-double;
}

.vl-vi-nav-right-double--after {
	@include vi-nav-right-double-after;
}

// ### ICON: nav-right-light
@mixin vi-nav-right-light {
	&::before {
		content: "\f1ec";
	}
}

@mixin vi-nav-right-light-after {
	&::after {
		content: "\f1ec";
	}
}

.vl-vi-nav-right-light {
	@include vi-nav-right-light;
}

.vl-vi-nav-right-light--after {
	@include vi-nav-right-light-after;
}

// ### ICON: nav-right
@mixin vi-nav-right {
	&::before {
		content: "\f1ed";
	}
}

@mixin vi-nav-right-after {
	&::after {
		content: "\f1ed";
	}
}

.vl-vi-nav-right {
	@include vi-nav-right;
}

.vl-vi-nav-right--after {
	@include vi-nav-right-after;
}

// ### ICON: nav-show-more-horizontal
@mixin vi-nav-show-more-horizontal {
	&::before {
		content: "\f1ee";
	}
}

@mixin vi-nav-show-more-horizontal-after {
	&::after {
		content: "\f1ee";
	}
}

.vl-vi-nav-show-more-horizontal {
	@include vi-nav-show-more-horizontal;
}

.vl-vi-nav-show-more-horizontal--after {
	@include vi-nav-show-more-horizontal-after;
}

// ### ICON: nav-show-more-vertical
@mixin vi-nav-show-more-vertical {
	&::before {
		content: "\f1ef";
	}
}

@mixin vi-nav-show-more-vertical-after {
	&::after {
		content: "\f1ef";
	}
}

.vl-vi-nav-show-more-vertical {
	@include vi-nav-show-more-vertical;
}

.vl-vi-nav-show-more-vertical--after {
	@include vi-nav-show-more-vertical-after;
}

// ### ICON: nav-up-double
@mixin vi-nav-up-double {
	&::before {
		content: "\f1f0";
	}
}

@mixin vi-nav-up-double-after {
	&::after {
		content: "\f1f0";
	}
}

.vl-vi-nav-up-double {
	@include vi-nav-up-double;
}

.vl-vi-nav-up-double--after {
	@include vi-nav-up-double-after;
}

// ### ICON: nav-up-light
@mixin vi-nav-up-light {
	&::before {
		content: "\f1f1";
	}
}

@mixin vi-nav-up-light-after {
	&::after {
		content: "\f1f1";
	}
}

.vl-vi-nav-up-light {
	@include vi-nav-up-light;
}

.vl-vi-nav-up-light--after {
	@include vi-nav-up-light-after;
}

// ### ICON: nav-up
@mixin vi-nav-up {
	&::before {
		content: "\f1f2";
	}
}

@mixin vi-nav-up-after {
	&::after {
		content: "\f1f2";
	}
}

.vl-vi-nav-up {
	@include vi-nav-up;
}

.vl-vi-nav-up--after {
	@include vi-nav-up-after;
}

// ### ICON: news
@mixin vi-news {
	&::before {
		content: "\f1f3";
	}
}

@mixin vi-news-after {
	&::after {
		content: "\f1f3";
	}
}

.vl-vi-news {
	@include vi-news;
}

.vl-vi-news--after {
	@include vi-news-after;
}

// ### ICON: newspaper
@mixin vi-newspaper {
	&::before {
		content: "\f1f4";
	}
}

@mixin vi-newspaper-after {
	&::after {
		content: "\f1f4";
	}
}

.vl-vi-newspaper {
	@include vi-newspaper;
}

.vl-vi-newspaper--after {
	@include vi-newspaper-after;
}

// ### ICON: next
@mixin vi-next {
	&::before {
		content: "\f1f5";
	}
}

@mixin vi-next-after {
	&::after {
		content: "\f1f5";
	}
}

.vl-vi-next {
	@include vi-next;
}

.vl-vi-next--after {
	@include vi-next-after;
}

// ### ICON: other-annoyances-alt
@mixin vi-other-annoyances-alt {
	&::before {
		content: "\f1f6";
	}
}

@mixin vi-other-annoyances-alt-after {
	&::after {
		content: "\f1f6";
	}
}

.vl-vi-other-annoyances-alt {
	@include vi-other-annoyances-alt;
}

.vl-vi-other-annoyances-alt--after {
	@include vi-other-annoyances-alt-after;
}

// ### ICON: other-annoyances
@mixin vi-other-annoyances {
	&::before {
		content: "\f1f7";
	}
}

@mixin vi-other-annoyances-after {
	&::after {
		content: "\f1f7";
	}
}

.vl-vi-other-annoyances {
	@include vi-other-annoyances;
}

.vl-vi-other-annoyances--after {
	@include vi-other-annoyances-after;
}

// ### ICON: paint-brush
@mixin vi-paint-brush {
	&::before {
		content: "\f1f8";
	}
}

@mixin vi-paint-brush-after {
	&::after {
		content: "\f1f8";
	}
}

.vl-vi-paint-brush {
	@include vi-paint-brush;
}

.vl-vi-paint-brush--after {
	@include vi-paint-brush-after;
}

// ### ICON: paper
@mixin vi-paper {
	&::before {
		content: "\f1f9";
	}
}

@mixin vi-paper-after {
	&::after {
		content: "\f1f9";
	}
}

.vl-vi-paper {
	@include vi-paper;
}

.vl-vi-paper--after {
	@include vi-paper-after;
}

// ### ICON: paperclip
@mixin vi-paperclip {
	&::before {
		content: "\f1fa";
	}
}

@mixin vi-paperclip-after {
	&::after {
		content: "\f1fa";
	}
}

.vl-vi-paperclip {
	@include vi-paperclip;
}

.vl-vi-paperclip--after {
	@include vi-paperclip-after;
}

// ### ICON: paragraph
@mixin vi-paragraph {
	&::before {
		content: "\f1fb";
	}
}

@mixin vi-paragraph-after {
	&::after {
		content: "\f1fb";
	}
}

.vl-vi-paragraph {
	@include vi-paragraph;
}

.vl-vi-paragraph--after {
	@include vi-paragraph-after;
}

// ### ICON: pause
@mixin vi-pause {
	&::before {
		content: "\f1fc";
	}
}

@mixin vi-pause-after {
	&::after {
		content: "\f1fc";
	}
}

.vl-vi-pause {
	@include vi-pause;
}

.vl-vi-pause--after {
	@include vi-pause-after;
}

// ### ICON: pencil-write
@mixin vi-pencil-write {
	&::before {
		content: "\f1fd";
	}
}

@mixin vi-pencil-write-after {
	&::after {
		content: "\f1fd";
	}
}

.vl-vi-pencil-write {
	@include vi-pencil-write;
}

.vl-vi-pencil-write--after {
	@include vi-pencil-write-after;
}

// ### ICON: pencil
@mixin vi-pencil {
	&::before {
		content: "\f1fe";
	}
}

@mixin vi-pencil-after {
	&::after {
		content: "\f1fe";
	}
}

.vl-vi-pencil {
	@include vi-pencil;
}

.vl-vi-pencil--after {
	@include vi-pencil-after;
}

// ### ICON: pennants
@mixin vi-pennants {
	&::before {
		content: "\f1ff";
	}
}

@mixin vi-pennants-after {
	&::after {
		content: "\f1ff";
	}
}

.vl-vi-pennants {
	@include vi-pennants;
}

.vl-vi-pennants--after {
	@include vi-pennants-after;
}

// ### ICON: phone-incoming
@mixin vi-phone-incoming {
	&::before {
		content: "\f200";
	}
}

@mixin vi-phone-incoming-after {
	&::after {
		content: "\f200";
	}
}

.vl-vi-phone-incoming {
	@include vi-phone-incoming;
}

.vl-vi-phone-incoming--after {
	@include vi-phone-incoming-after;
}

// ### ICON: phone-outgoing
@mixin vi-phone-outgoing {
	&::before {
		content: "\f201";
	}
}

@mixin vi-phone-outgoing-after {
	&::after {
		content: "\f201";
	}
}

.vl-vi-phone-outgoing {
	@include vi-phone-outgoing;
}

.vl-vi-phone-outgoing--after {
	@include vi-phone-outgoing-after;
}

// ### ICON: phone-record
@mixin vi-phone-record {
	&::before {
		content: "\f202";
	}
}

@mixin vi-phone-record-after {
	&::after {
		content: "\f202";
	}
}

.vl-vi-phone-record {
	@include vi-phone-record;
}

.vl-vi-phone-record--after {
	@include vi-phone-record-after;
}

// ### ICON: phone-signal-low
@mixin vi-phone-signal-low {
	&::before {
		content: "\f203";
	}
}

@mixin vi-phone-signal-low-after {
	&::after {
		content: "\f203";
	}
}

.vl-vi-phone-signal-low {
	@include vi-phone-signal-low;
}

.vl-vi-phone-signal-low--after {
	@include vi-phone-signal-low-after;
}

// ### ICON: phone-speaker
@mixin vi-phone-speaker {
	&::before {
		content: "\f204";
	}
}

@mixin vi-phone-speaker-after {
	&::after {
		content: "\f204";
	}
}

.vl-vi-phone-speaker {
	@include vi-phone-speaker;
}

.vl-vi-phone-speaker--after {
	@include vi-phone-speaker-after;
}

// ### ICON: phone
@mixin vi-phone {
	&::before {
		content: "\f205";
	}
}

@mixin vi-phone-after {
	&::after {
		content: "\f205";
	}
}

.vl-vi-phone {
	@include vi-phone;
}

.vl-vi-phone--after {
	@include vi-phone-after;
}

// ### ICON: pin-paper
@mixin vi-pin-paper {
	&::before {
		content: "\f206";
	}
}

@mixin vi-pin-paper-after {
	&::after {
		content: "\f206";
	}
}

.vl-vi-pin-paper {
	@include vi-pin-paper;
}

.vl-vi-pin-paper--after {
	@include vi-pin-paper-after;
}

// ### ICON: pin
@mixin vi-pin {
	&::before {
		content: "\f207";
	}
}

@mixin vi-pin-after {
	&::after {
		content: "\f207";
	}
}

.vl-vi-pin {
	@include vi-pin;
}

.vl-vi-pin--after {
	@include vi-pin-after;
}

// ### ICON: pinterest
@mixin vi-pinterest {
	&::before {
		content: "\f208";
	}
}

@mixin vi-pinterest-after {
	&::after {
		content: "\f208";
	}
}

.vl-vi-pinterest {
	@include vi-pinterest;
}

.vl-vi-pinterest--after {
	@include vi-pinterest-after;
}

// ### ICON: places-factory
@mixin vi-places-factory {
	&::before {
		content: "\f209";
	}
}

@mixin vi-places-factory-after {
	&::after {
		content: "\f209";
	}
}

.vl-vi-places-factory {
	@include vi-places-factory;
}

.vl-vi-places-factory--after {
	@include vi-places-factory-after;
}

// ### ICON: places-home
@mixin vi-places-home {
	&::before {
		content: "\f20a";
	}
}

@mixin vi-places-home-after {
	&::after {
		content: "\f20a";
	}
}

.vl-vi-places-home {
	@include vi-places-home;
}

.vl-vi-places-home--after {
	@include vi-places-home-after;
}

// ### ICON: play
@mixin vi-play {
	&::before {
		content: "\f20b";
	}
}

@mixin vi-play-after {
	&::after {
		content: "\f20b";
	}
}

.vl-vi-play {
	@include vi-play;
}

.vl-vi-play--after {
	@include vi-play-after;
}

// ### ICON: playstreet
@mixin vi-playstreet {
	&::before {
		content: "\f20c";
	}
}

@mixin vi-playstreet-after {
	&::after {
		content: "\f20c";
	}
}

.vl-vi-playstreet {
	@include vi-playstreet;
}

.vl-vi-playstreet--after {
	@include vi-playstreet-after;
}

// ### ICON: plug
@mixin vi-plug {
	&::before {
		content: "\f20d";
	}
}

@mixin vi-plug-after {
	&::after {
		content: "\f20d";
	}
}

.vl-vi-plug {
	@include vi-plug;
}

.vl-vi-plug--after {
	@include vi-plug-after;
}

// ### ICON: plus-circle
@mixin vi-plus-circle {
	&::before {
		content: "\f20e";
	}
}

@mixin vi-plus-circle-after {
	&::after {
		content: "\f20e";
	}
}

.vl-vi-plus-circle {
	@include vi-plus-circle;
}

.vl-vi-plus-circle--after {
	@include vi-plus-circle-after;
}

// ### ICON: plus
@mixin vi-plus {
	&::before {
		content: "\f20f";
	}
}

@mixin vi-plus-after {
	&::after {
		content: "\f20f";
	}
}

.vl-vi-plus {
	@include vi-plus;
}

.vl-vi-plus--after {
	@include vi-plus-after;
}

// ### ICON: power-button
@mixin vi-power-button {
	&::before {
		content: "\f210";
	}
}

@mixin vi-power-button-after {
	&::after {
		content: "\f210";
	}
}

.vl-vi-power-button {
	@include vi-power-button;
}

.vl-vi-power-button--after {
	@include vi-power-button-after;
}

// ### ICON: printer-view
@mixin vi-printer-view {
	&::before {
		content: "\f211";
	}
}

@mixin vi-printer-view-after {
	&::after {
		content: "\f211";
	}
}

.vl-vi-printer-view {
	@include vi-printer-view;
}

.vl-vi-printer-view--after {
	@include vi-printer-view-after;
}

// ### ICON: programming-bug
@mixin vi-programming-bug {
	&::before {
		content: "\f212";
	}
}

@mixin vi-programming-bug-after {
	&::after {
		content: "\f212";
	}
}

.vl-vi-programming-bug {
	@include vi-programming-bug;
}

.vl-vi-programming-bug--after {
	@include vi-programming-bug-after;
}

// ### ICON: question-mark-filled
@mixin vi-question-mark-filled {
	&::before {
		content: "\f213";
	}
}

@mixin vi-question-mark-filled-after {
	&::after {
		content: "\f213";
	}
}

.vl-vi-question-mark-filled {
	@include vi-question-mark-filled;
}

.vl-vi-question-mark-filled--after {
	@include vi-question-mark-filled-after;
}

// ### ICON: question-mark-small
@mixin vi-question-mark-small {
	&::before {
		content: "\f214";
	}
}

@mixin vi-question-mark-small-after {
	&::after {
		content: "\f214";
	}
}

.vl-vi-question-mark-small {
	@include vi-question-mark-small;
}

.vl-vi-question-mark-small--after {
	@include vi-question-mark-small-after;
}

// ### ICON: question-mark
@mixin vi-question-mark {
	&::before {
		content: "\f215";
	}
}

@mixin vi-question-mark-after {
	&::after {
		content: "\f215";
	}
}

.vl-vi-question-mark {
	@include vi-question-mark;
}

.vl-vi-question-mark--after {
	@include vi-question-mark-after;
}

// ### ICON: question
@mixin vi-question {
	&::before {
		content: "\f216";
	}
}

@mixin vi-question-after {
	&::after {
		content: "\f216";
	}
}

.vl-vi-question {
	@include vi-question;
}

.vl-vi-question--after {
	@include vi-question-after;
}

// ### ICON: recreation
@mixin vi-recreation {
	&::before {
		content: "\f217";
	}
}

@mixin vi-recreation-after {
	&::after {
		content: "\f217";
	}
}

.vl-vi-recreation {
	@include vi-recreation;
}

.vl-vi-recreation--after {
	@include vi-recreation-after;
}

// ### ICON: reply-all
@mixin vi-reply-all {
	&::before {
		content: "\f218";
	}
}

@mixin vi-reply-all-after {
	&::after {
		content: "\f218";
	}
}

.vl-vi-reply-all {
	@include vi-reply-all;
}

.vl-vi-reply-all--after {
	@include vi-reply-all-after;
}

// ### ICON: reply
@mixin vi-reply {
	&::before {
		content: "\f219";
	}
}

@mixin vi-reply-after {
	&::after {
		content: "\f219";
	}
}

.vl-vi-reply {
	@include vi-reply;
}

.vl-vi-reply--after {
	@include vi-reply-after;
}

// ### ICON: rewards-certified-badge
@mixin vi-rewards-certified-badge {
	&::before {
		content: "\f21a";
	}
}

@mixin vi-rewards-certified-badge-after {
	&::after {
		content: "\f21a";
	}
}

.vl-vi-rewards-certified-badge {
	@include vi-rewards-certified-badge;
}

.vl-vi-rewards-certified-badge--after {
	@include vi-rewards-certified-badge-after;
}

// ### ICON: rewards-gift
@mixin vi-rewards-gift {
	&::before {
		content: "\f21b";
	}
}

@mixin vi-rewards-gift-after {
	&::after {
		content: "\f21b";
	}
}

.vl-vi-rewards-gift {
	@include vi-rewards-gift;
}

.vl-vi-rewards-gift--after {
	@include vi-rewards-gift-after;
}

// ### ICON: road-block
@mixin vi-road-block {
	&::before {
		content: "\f21c";
	}
}

@mixin vi-road-block-after {
	&::after {
		content: "\f21c";
	}
}

.vl-vi-road-block {
	@include vi-road-block;
}

.vl-vi-road-block--after {
	@include vi-road-block-after;
}

// ### ICON: road
@mixin vi-road {
	&::before {
		content: "\f21d";
	}
}

@mixin vi-road-after {
	&::after {
		content: "\f21d";
	}
}

.vl-vi-road {
	@include vi-road;
}

.vl-vi-road--after {
	@include vi-road-after;
}

// ### ICON: romance-marriage-license
@mixin vi-romance-marriage-license {
	&::before {
		content: "\f21e";
	}
}

@mixin vi-romance-marriage-license-after {
	&::after {
		content: "\f21e";
	}
}

.vl-vi-romance-marriage-license {
	@include vi-romance-marriage-license;
}

.vl-vi-romance-marriage-license--after {
	@include vi-romance-marriage-license-after;
}

// ### ICON: save
@mixin vi-save {
	&::before {
		content: "\f21f";
	}
}

@mixin vi-save-after {
	&::after {
		content: "\f21f";
	}
}

.vl-vi-save {
	@include vi-save;
}

.vl-vi-save--after {
	@include vi-save-after;
}

// ### ICON: scaffold
@mixin vi-scaffold {
	&::before {
		content: "\f220";
	}
}

@mixin vi-scaffold-after {
	&::after {
		content: "\f220";
	}
}

.vl-vi-scaffold {
	@include vi-scaffold;
}

.vl-vi-scaffold--after {
	@include vi-scaffold-after;
}

// ### ICON: scissors
@mixin vi-scissors {
	&::before {
		content: "\f221";
	}
}

@mixin vi-scissors-after {
	&::after {
		content: "\f221";
	}
}

.vl-vi-scissors {
	@include vi-scissors;
}

.vl-vi-scissors--after {
	@include vi-scissors-after;
}

// ### ICON: search
@mixin vi-search {
	&::before {
		content: "\f222";
	}
}

@mixin vi-search-after {
	&::after {
		content: "\f222";
	}
}

.vl-vi-search {
	@include vi-search;
}

.vl-vi-search--after {
	@include vi-search-after;
}

// ### ICON: server
@mixin vi-server {
	&::before {
		content: "\f223";
	}
}

@mixin vi-server-after {
	&::after {
		content: "\f223";
	}
}

.vl-vi-server {
	@include vi-server;
}

.vl-vi-server--after {
	@include vi-server-after;
}

// ### ICON: settings
@mixin vi-settings {
	&::before {
		content: "\f224";
	}
}

@mixin vi-settings-after {
	&::after {
		content: "\f224";
	}
}

.vl-vi-settings {
	@include vi-settings;
}

.vl-vi-settings--after {
	@include vi-settings-after;
}

// ### ICON: share-megaphone
@mixin vi-share-megaphone {
	&::before {
		content: "\f225";
	}
}

@mixin vi-share-megaphone-after {
	&::after {
		content: "\f225";
	}
}

.vl-vi-share-megaphone {
	@include vi-share-megaphone;
}

.vl-vi-share-megaphone--after {
	@include vi-share-megaphone-after;
}

// ### ICON: share-rss-feed
@mixin vi-share-rss-feed {
	&::before {
		content: "\f226";
	}
}

@mixin vi-share-rss-feed-after {
	&::after {
		content: "\f226";
	}
}

.vl-vi-share-rss-feed {
	@include vi-share-rss-feed;
}

.vl-vi-share-rss-feed--after {
	@include vi-share-rss-feed-after;
}

// ### ICON: share
@mixin vi-share {
	&::before {
		content: "\f227";
	}
}

@mixin vi-share-after {
	&::after {
		content: "\f227";
	}
}

.vl-vi-share {
	@include vi-share;
}

.vl-vi-share--after {
	@include vi-share-after;
}

// ### ICON: shipping-truck
@mixin vi-shipping-truck {
	&::before {
		content: "\f228";
	}
}

@mixin vi-shipping-truck-after {
	&::after {
		content: "\f228";
	}
}

.vl-vi-shipping-truck {
	@include vi-shipping-truck;
}

.vl-vi-shipping-truck--after {
	@include vi-shipping-truck-after;
}

// ### ICON: shopping-basket-add
@mixin vi-shopping-basket-add {
	&::before {
		content: "\f229";
	}
}

@mixin vi-shopping-basket-add-after {
	&::after {
		content: "\f229";
	}
}

.vl-vi-shopping-basket-add {
	@include vi-shopping-basket-add;
}

.vl-vi-shopping-basket-add--after {
	@include vi-shopping-basket-add-after;
}

// ### ICON: shopping-basket-subtract
@mixin vi-shopping-basket-subtract {
	&::before {
		content: "\f22a";
	}
}

@mixin vi-shopping-basket-subtract-after {
	&::after {
		content: "\f22a";
	}
}

.vl-vi-shopping-basket-subtract {
	@include vi-shopping-basket-subtract;
}

.vl-vi-shopping-basket-subtract--after {
	@include vi-shopping-basket-subtract-after;
}

// ### ICON: shopping-basket
@mixin vi-shopping-basket {
	&::before {
		content: "\f22b";
	}
}

@mixin vi-shopping-basket-after {
	&::after {
		content: "\f22b";
	}
}

.vl-vi-shopping-basket {
	@include vi-shopping-basket;
}

.vl-vi-shopping-basket--after {
	@include vi-shopping-basket-after;
}

// ### ICON: shrink
@mixin vi-shrink {
	&::before {
		content: "\f22c";
	}
}

@mixin vi-shrink-after {
	&::after {
		content: "\f22c";
	}
}

.vl-vi-shrink {
	@include vi-shrink;
}

.vl-vi-shrink--after {
	@include vi-shrink-after;
}

// ### ICON: sign-disable
@mixin vi-sign-disable {
	&::before {
		content: "\f22d";
	}
}

@mixin vi-sign-disable-after {
	&::after {
		content: "\f22d";
	}
}

.vl-vi-sign-disable {
	@include vi-sign-disable;
}

.vl-vi-sign-disable--after {
	@include vi-sign-disable-after;
}

// ### ICON: sign-recycle
@mixin vi-sign-recycle {
	&::before {
		content: "\f22e";
	}
}

@mixin vi-sign-recycle-after {
	&::after {
		content: "\f22e";
	}
}

.vl-vi-sign-recycle {
	@include vi-sign-recycle;
}

.vl-vi-sign-recycle--after {
	@include vi-sign-recycle-after;
}

// ### ICON: sitemap
@mixin vi-sitemap {
	&::before {
		content: "\f22f";
	}
}

@mixin vi-sitemap-after {
	&::after {
		content: "\f22f";
	}
}

.vl-vi-sitemap {
	@include vi-sitemap;
}

.vl-vi-sitemap--after {
	@include vi-sitemap-after;
}

// ### ICON: skype
@mixin vi-skype {
	&::before {
		content: "\f230";
	}
}

@mixin vi-skype-after {
	&::after {
		content: "\f230";
	}
}

.vl-vi-skype {
	@include vi-skype;
}

.vl-vi-skype--after {
	@include vi-skype-after;
}

// ### ICON: smiley-poker-face
@mixin vi-smiley-poker-face {
	&::before {
		content: "\f231";
	}
}

@mixin vi-smiley-poker-face-after {
	&::after {
		content: "\f231";
	}
}

.vl-vi-smiley-poker-face {
	@include vi-smiley-poker-face;
}

.vl-vi-smiley-poker-face--after {
	@include vi-smiley-poker-face-after;
}

// ### ICON: smiley-smile
@mixin vi-smiley-smile {
	&::before {
		content: "\f232";
	}
}

@mixin vi-smiley-smile-after {
	&::after {
		content: "\f232";
	}
}

.vl-vi-smiley-smile {
	@include vi-smiley-smile;
}

.vl-vi-smiley-smile--after {
	@include vi-smiley-smile-after;
}

// ### ICON: snapchat
@mixin vi-snapchat {
	&::before {
		content: "\f233";
	}
}

@mixin vi-snapchat-after {
	&::after {
		content: "\f233";
	}
}

.vl-vi-snapchat {
	@include vi-snapchat;
}

.vl-vi-snapchat--after {
	@include vi-snapchat-after;
}

// ### ICON: sort
@mixin vi-sort {
	&::before {
		content: "\f234";
	}
}

@mixin vi-sort-after {
	&::after {
		content: "\f234";
	}
}

.vl-vi-sort {
	@include vi-sort;
}

.vl-vi-sort--after {
	@include vi-sort-after;
}

// ### ICON: speaker-volume-decrease
@mixin vi-speaker-volume-decrease {
	&::before {
		content: "\f235";
	}
}

@mixin vi-speaker-volume-decrease-after {
	&::after {
		content: "\f235";
	}
}

.vl-vi-speaker-volume-decrease {
	@include vi-speaker-volume-decrease;
}

.vl-vi-speaker-volume-decrease--after {
	@include vi-speaker-volume-decrease-after;
}

// ### ICON: speaker-volume-high
@mixin vi-speaker-volume-high {
	&::before {
		content: "\f236";
	}
}

@mixin vi-speaker-volume-high-after {
	&::after {
		content: "\f236";
	}
}

.vl-vi-speaker-volume-high {
	@include vi-speaker-volume-high;
}

.vl-vi-speaker-volume-high--after {
	@include vi-speaker-volume-high-after;
}

// ### ICON: speaker-volume-increase
@mixin vi-speaker-volume-increase {
	&::before {
		content: "\f237";
	}
}

@mixin vi-speaker-volume-increase-after {
	&::after {
		content: "\f237";
	}
}

.vl-vi-speaker-volume-increase {
	@include vi-speaker-volume-increase;
}

.vl-vi-speaker-volume-increase--after {
	@include vi-speaker-volume-increase-after;
}

// ### ICON: speaker-volume-low
@mixin vi-speaker-volume-low {
	&::before {
		content: "\f238";
	}
}

@mixin vi-speaker-volume-low-after {
	&::after {
		content: "\f238";
	}
}

.vl-vi-speaker-volume-low {
	@include vi-speaker-volume-low;
}

.vl-vi-speaker-volume-low--after {
	@include vi-speaker-volume-low-after;
}

// ### ICON: speaker-volume-medium
@mixin vi-speaker-volume-medium {
	&::before {
		content: "\f239";
	}
}

@mixin vi-speaker-volume-medium-after {
	&::after {
		content: "\f239";
	}
}

.vl-vi-speaker-volume-medium {
	@include vi-speaker-volume-medium;
}

.vl-vi-speaker-volume-medium--after {
	@include vi-speaker-volume-medium-after;
}

// ### ICON: speaker-volume-off
@mixin vi-speaker-volume-off {
	&::before {
		content: "\f23a";
	}
}

@mixin vi-speaker-volume-off-after {
	&::after {
		content: "\f23a";
	}
}

.vl-vi-speaker-volume-off {
	@include vi-speaker-volume-off;
}

.vl-vi-speaker-volume-off--after {
	@include vi-speaker-volume-off-after;
}

// ### ICON: sports-competition
@mixin vi-sports-competition {
	&::before {
		content: "\f23b";
	}
}

@mixin vi-sports-competition-after {
	&::after {
		content: "\f23b";
	}
}

.vl-vi-sports-competition {
	@include vi-sports-competition;
}

.vl-vi-sports-competition--after {
	@include vi-sports-competition-after;
}

// ### ICON: spotify
@mixin vi-spotify {
	&::before {
		content: "\f23c";
	}
}

@mixin vi-spotify-after {
	&::after {
		content: "\f23c";
	}
}

.vl-vi-spotify {
	@include vi-spotify;
}

.vl-vi-spotify--after {
	@include vi-spotify-after;
}

// ### ICON: stop
@mixin vi-stop {
	&::before {
		content: "\f23d";
	}
}

@mixin vi-stop-after {
	&::after {
		content: "\f23d";
	}
}

.vl-vi-stop {
	@include vi-stop;
}

.vl-vi-stop--after {
	@include vi-stop-after;
}

// ### ICON: subtract
@mixin vi-subtract {
	&::before {
		content: "\f23e";
	}
}

@mixin vi-subtract-after {
	&::after {
		content: "\f23e";
	}
}

.vl-vi-subtract {
	@include vi-subtract;
}

.vl-vi-subtract--after {
	@include vi-subtract-after;
}

// ### ICON: subway
@mixin vi-subway {
	&::before {
		content: "\f23f";
	}
}

@mixin vi-subway-after {
	&::after {
		content: "\f23f";
	}
}

.vl-vi-subway {
	@include vi-subway;
}

.vl-vi-subway--after {
	@include vi-subway-after;
}

// ### ICON: suitcase
@mixin vi-suitcase {
	&::before {
		content: "\f240";
	}
}

@mixin vi-suitcase-after {
	&::after {
		content: "\f240";
	}
}

.vl-vi-suitcase {
	@include vi-suitcase;
}

.vl-vi-suitcase--after {
	@include vi-suitcase-after;
}

// ### ICON: switches
@mixin vi-switches {
	&::before {
		content: "\f241";
	}
}

@mixin vi-switches-after {
	&::after {
		content: "\f241";
	}
}

.vl-vi-switches {
	@include vi-switches;
}

.vl-vi-switches--after {
	@include vi-switches-after;
}

// ### ICON: symbol-wifi
@mixin vi-symbol-wifi {
	&::before {
		content: "\f242";
	}
}

@mixin vi-symbol-wifi-after {
	&::after {
		content: "\f242";
	}
}

.vl-vi-symbol-wifi {
	@include vi-symbol-wifi;
}

.vl-vi-symbol-wifi--after {
	@include vi-symbol-wifi-after;
}

// ### ICON: synchronize-timeout
@mixin vi-synchronize-timeout {
	&::before {
		content: "\f243";
	}
}

@mixin vi-synchronize-timeout-after {
	&::after {
		content: "\f243";
	}
}

.vl-vi-synchronize-timeout {
	@include vi-synchronize-timeout;
}

.vl-vi-synchronize-timeout--after {
	@include vi-synchronize-timeout-after;
}

// ### ICON: synchronize
@mixin vi-synchronize {
	&::before {
		content: "\f244";
	}
}

@mixin vi-synchronize-after {
	&::after {
		content: "\f244";
	}
}

.vl-vi-synchronize {
	@include vi-synchronize;
}

.vl-vi-synchronize--after {
	@include vi-synchronize-after;
}

// ### ICON: tag-add
@mixin vi-tag-add {
	&::before {
		content: "\f245";
	}
}

@mixin vi-tag-add-after {
	&::after {
		content: "\f245";
	}
}

.vl-vi-tag-add {
	@include vi-tag-add;
}

.vl-vi-tag-add--after {
	@include vi-tag-add-after;
}

// ### ICON: tag-check
@mixin vi-tag-check {
	&::before {
		content: "\f246";
	}
}

@mixin vi-tag-check-after {
	&::after {
		content: "\f246";
	}
}

.vl-vi-tag-check {
	@include vi-tag-check;
}

.vl-vi-tag-check--after {
	@include vi-tag-check-after;
}

// ### ICON: tag-close
@mixin vi-tag-close {
	&::before {
		content: "\f247";
	}
}

@mixin vi-tag-close-after {
	&::after {
		content: "\f247";
	}
}

.vl-vi-tag-close {
	@include vi-tag-close;
}

.vl-vi-tag-close--after {
	@include vi-tag-close-after;
}

// ### ICON: tag-double
@mixin vi-tag-double {
	&::before {
		content: "\f248";
	}
}

@mixin vi-tag-double-after {
	&::after {
		content: "\f248";
	}
}

.vl-vi-tag-double {
	@include vi-tag-double;
}

.vl-vi-tag-double--after {
	@include vi-tag-double-after;
}

// ### ICON: tag-edit
@mixin vi-tag-edit {
	&::before {
		content: "\f249";
	}
}

@mixin vi-tag-edit-after {
	&::after {
		content: "\f249";
	}
}

.vl-vi-tag-edit {
	@include vi-tag-edit;
}

.vl-vi-tag-edit--after {
	@include vi-tag-edit-after;
}

// ### ICON: tag-subtract
@mixin vi-tag-subtract {
	&::before {
		content: "\f24a";
	}
}

@mixin vi-tag-subtract-after {
	&::after {
		content: "\f24a";
	}
}

.vl-vi-tag-subtract {
	@include vi-tag-subtract;
}

.vl-vi-tag-subtract--after {
	@include vi-tag-subtract-after;
}

// ### ICON: tag-view
@mixin vi-tag-view {
	&::before {
		content: "\f24b";
	}
}

@mixin vi-tag-view-after {
	&::after {
		content: "\f24b";
	}
}

.vl-vi-tag-view {
	@include vi-tag-view;
}

.vl-vi-tag-view--after {
	@include vi-tag-view-after;
}

// ### ICON: tag
@mixin vi-tag {
	&::before {
		content: "\f24c";
	}
}

@mixin vi-tag-after {
	&::after {
		content: "\f24c";
	}
}

.vl-vi-tag {
	@include vi-tag;
}

.vl-vi-tag--after {
	@include vi-tag-after;
}

// ### ICON: taxi
@mixin vi-taxi {
	&::before {
		content: "\f24d";
	}
}

@mixin vi-taxi-after {
	&::after {
		content: "\f24d";
	}
}

.vl-vi-taxi {
	@include vi-taxi;
}

.vl-vi-taxi--after {
	@include vi-taxi-after;
}

// ### ICON: television
@mixin vi-television {
	&::before {
		content: "\f24e";
	}
}

@mixin vi-television-after {
	&::after {
		content: "\f24e";
	}
}

.vl-vi-television {
	@include vi-television;
}

.vl-vi-television--after {
	@include vi-television-after;
}

// ### ICON: terrace
@mixin vi-terrace {
	&::before {
		content: "\f24f";
	}
}

@mixin vi-terrace-after {
	&::after {
		content: "\f24f";
	}
}

.vl-vi-terrace {
	@include vi-terrace;
}

.vl-vi-terrace--after {
	@include vi-terrace-after;
}

// ### ICON: text-cursor
@mixin vi-text-cursor {
	&::before {
		content: "\f250";
	}
}

@mixin vi-text-cursor-after {
	&::after {
		content: "\f250";
	}
}

.vl-vi-text-cursor {
	@include vi-text-cursor;
}

.vl-vi-text-cursor--after {
	@include vi-text-cursor-after;
}

// ### ICON: text-eraser
@mixin vi-text-eraser {
	&::before {
		content: "\f251";
	}
}

@mixin vi-text-eraser-after {
	&::after {
		content: "\f251";
	}
}

.vl-vi-text-eraser {
	@include vi-text-eraser;
}

.vl-vi-text-eraser--after {
	@include vi-text-eraser-after;
}

// ### ICON: text-redo
@mixin vi-text-redo {
	&::before {
		content: "\f252";
	}
}

@mixin vi-text-redo-after {
	&::after {
		content: "\f252";
	}
}

.vl-vi-text-redo {
	@include vi-text-redo;
}

.vl-vi-text-redo--after {
	@include vi-text-redo-after;
}

// ### ICON: text-undo
@mixin vi-text-undo {
	&::before {
		content: "\f253";
	}
}

@mixin vi-text-undo-after {
	&::after {
		content: "\f253";
	}
}

.vl-vi-text-undo {
	@include vi-text-undo;
}

.vl-vi-text-undo--after {
	@include vi-text-undo-after;
}

// ### ICON: timeline
@mixin vi-timeline {
	&::before {
		content: "\f254";
	}
}

@mixin vi-timeline-after {
	&::after {
		content: "\f254";
	}
}

.vl-vi-timeline {
	@include vi-timeline;
}

.vl-vi-timeline--after {
	@include vi-timeline-after;
}

// ### ICON: tint
@mixin vi-tint {
	&::before {
		content: "\f255";
	}
}

@mixin vi-tint-after {
	&::after {
		content: "\f255";
	}
}

.vl-vi-tint {
	@include vi-tint;
}

.vl-vi-tint--after {
	@include vi-tint-after;
}

// ### ICON: train
@mixin vi-train {
	&::before {
		content: "\f256";
	}
}

@mixin vi-train-after {
	&::after {
		content: "\f256";
	}
}

.vl-vi-train {
	@include vi-train;
}

.vl-vi-train--after {
	@include vi-train-after;
}

// ### ICON: trash
@mixin vi-trash {
	&::before {
		content: "\f257";
	}
}

@mixin vi-trash-after {
	&::after {
		content: "\f257";
	}
}

.vl-vi-trash {
	@include vi-trash;
}

.vl-vi-trash--after {
	@include vi-trash-after;
}

// ### ICON: trophy
@mixin vi-trophy {
	&::before {
		content: "\f258";
	}
}

@mixin vi-trophy-after {
	&::after {
		content: "\f258";
	}
}

.vl-vi-trophy {
	@include vi-trophy;
}

.vl-vi-trophy--after {
	@include vi-trophy-after;
}

// ### ICON: twitter
@mixin vi-twitter {
	&::before {
		content: "\f259";
	}
}

@mixin vi-twitter-after {
	&::after {
		content: "\f259";
	}
}

.vl-vi-twitter {
	@include vi-twitter;
}

.vl-vi-twitter--after {
	@include vi-twitter-after;
}

// ### ICON: underline
@mixin vi-underline {
	&::before {
		content: "\f25a";
	}
}

@mixin vi-underline-after {
	&::after {
		content: "\f25a";
	}
}

.vl-vi-underline {
	@include vi-underline;
}

.vl-vi-underline--after {
	@include vi-underline-after;
}

// ### ICON: university
@mixin vi-university {
	&::before {
		content: "\f25b";
	}
}

@mixin vi-university-after {
	&::after {
		content: "\f25b";
	}
}

.vl-vi-university {
	@include vi-university;
}

.vl-vi-university--after {
	@include vi-university-after;
}

// ### ICON: up-down-arrows
@mixin vi-up-down-arrows {
	&::before {
		content: "\f25c";
	}
}

@mixin vi-up-down-arrows-after {
	&::after {
		content: "\f25c";
	}
}

.vl-vi-up-down-arrows {
	@include vi-up-down-arrows;
}

.vl-vi-up-down-arrows--after {
	@include vi-up-down-arrows-after;
}

// ### ICON: upload-harddisk
@mixin vi-upload-harddisk {
	&::before {
		content: "\f25d";
	}
}

@mixin vi-upload-harddisk-after {
	&::after {
		content: "\f25d";
	}
}

.vl-vi-upload-harddisk {
	@include vi-upload-harddisk;
}

.vl-vi-upload-harddisk--after {
	@include vi-upload-harddisk-after;
}

// ### ICON: user-female
@mixin vi-user-female {
	&::before {
		content: "\f25e";
	}
}

@mixin vi-user-female-after {
	&::after {
		content: "\f25e";
	}
}

.vl-vi-user-female {
	@include vi-user-female;
}

.vl-vi-user-female--after {
	@include vi-user-female-after;
}

// ### ICON: user-group
@mixin vi-user-group {
	&::before {
		content: "\f25f";
	}
}

@mixin vi-user-group-after {
	&::after {
		content: "\f25f";
	}
}

.vl-vi-user-group {
	@include vi-user-group;
}

.vl-vi-user-group--after {
	@include vi-user-group-after;
}

// ### ICON: user-male
@mixin vi-user-male {
	&::before {
		content: "\f260";
	}
}

@mixin vi-user-male-after {
	&::after {
		content: "\f260";
	}
}

.vl-vi-user-male {
	@include vi-user-male;
}

.vl-vi-user-male--after {
	@include vi-user-male-after;
}

// ### ICON: user-setting
@mixin vi-user-setting {
	&::before {
		content: "\f261";
	}
}

@mixin vi-user-setting-after {
	&::after {
		content: "\f261";
	}
}

.vl-vi-user-setting {
	@include vi-user-setting;
}

.vl-vi-user-setting--after {
	@include vi-user-setting-after;
}

// ### ICON: video-subtitle
@mixin vi-video-subtitle {
	&::before {
		content: "\f262";
	}
}

@mixin vi-video-subtitle-after {
	&::after {
		content: "\f262";
	}
}

.vl-vi-video-subtitle {
	@include vi-video-subtitle;
}

.vl-vi-video-subtitle--after {
	@include vi-video-subtitle-after;
}

// ### ICON: view-add
@mixin vi-view-add {
	&::before {
		content: "\f263";
	}
}

@mixin vi-view-add-after {
	&::after {
		content: "\f263";
	}
}

.vl-vi-view-add {
	@include vi-view-add;
}

.vl-vi-view-add--after {
	@include vi-view-add-after;
}

// ### ICON: vlaanderen
@mixin vi-vlaanderen {
	&::before {
		content: "\f264";
	}
}

@mixin vi-vlaanderen-after {
	&::after {
		content: "\f264";
	}
}

.vl-vi-vlaanderen {
	@include vi-vlaanderen;
}

.vl-vi-vlaanderen--after {
	@include vi-vlaanderen-after;
}

// ### ICON: vote-flag
@mixin vi-vote-flag {
	&::before {
		content: "\f265";
	}
}

@mixin vi-vote-flag-after {
	&::after {
		content: "\f265";
	}
}

.vl-vi-vote-flag {
	@include vi-vote-flag;
}

.vl-vi-vote-flag--after {
	@include vi-vote-flag-after;
}

// ### ICON: vote-heart
@mixin vi-vote-heart {
	&::before {
		content: "\f266";
	}
}

@mixin vi-vote-heart-after {
	&::after {
		content: "\f266";
	}
}

.vl-vi-vote-heart {
	@include vi-vote-heart;
}

.vl-vi-vote-heart--after {
	@include vi-vote-heart-after;
}

// ### ICON: vote-star
@mixin vi-vote-star {
	&::before {
		content: "\f267";
	}
}

@mixin vi-vote-star-after {
	&::after {
		content: "\f267";
	}
}

.vl-vi-vote-star {
	@include vi-vote-star;
}

.vl-vi-vote-star--after {
	@include vi-vote-star-after;
}

// ### ICON: vote-thumbs-down
@mixin vi-vote-thumbs-down {
	&::before {
		content: "\f268";
	}
}

@mixin vi-vote-thumbs-down-after {
	&::after {
		content: "\f268";
	}
}

.vl-vi-vote-thumbs-down {
	@include vi-vote-thumbs-down;
}

.vl-vi-vote-thumbs-down--after {
	@include vi-vote-thumbs-down-after;
}

// ### ICON: vote-thumbs-up
@mixin vi-vote-thumbs-up {
	&::before {
		content: "\f269";
	}
}

@mixin vi-vote-thumbs-up-after {
	&::after {
		content: "\f269";
	}
}

.vl-vi-vote-thumbs-up {
	@include vi-vote-thumbs-up;
}

.vl-vi-vote-thumbs-up--after {
	@include vi-vote-thumbs-up-after;
}

// ### ICON: warning
@mixin vi-warning {
	&::before {
		content: "\f26a";
	}
}

@mixin vi-warning-after {
	&::after {
		content: "\f26a";
	}
}

.vl-vi-warning {
	@include vi-warning;
}

.vl-vi-warning--after {
	@include vi-warning-after;
}

// ### ICON: whatsapp
@mixin vi-whatsapp {
	&::before {
		content: "\f26b";
	}
}

@mixin vi-whatsapp-after {
	&::after {
		content: "\f26b";
	}
}

.vl-vi-whatsapp {
	@include vi-whatsapp;
}

.vl-vi-whatsapp--after {
	@include vi-whatsapp-after;
}

// ### ICON: wrench
@mixin vi-wrench {
	&::before {
		content: "\f26c";
	}
}

@mixin vi-wrench-after {
	&::after {
		content: "\f26c";
	}
}

.vl-vi-wrench {
	@include vi-wrench;
}

.vl-vi-wrench--after {
	@include vi-wrench-after;
}

// ### ICON: youtube
@mixin vi-youtube {
	&::before {
		content: "\f26d";
	}
}

@mixin vi-youtube-after {
	&::after {
		content: "\f26d";
	}
}

.vl-vi-youtube {
	@include vi-youtube;
}

.vl-vi-youtube--after {
	@include vi-youtube-after;
}

// ### ICON: zoom-in
@mixin vi-zoom-in {
	&::before {
		content: "\f26e";
	}
}

@mixin vi-zoom-in-after {
	&::after {
		content: "\f26e";
	}
}

.vl-vi-zoom-in {
	@include vi-zoom-in;
}

.vl-vi-zoom-in--after {
	@include vi-zoom-in-after;
}

// ### ICON: zoom-out
@mixin vi-zoom-out {
	&::before {
		content: "\f26f";
	}
}

@mixin vi-zoom-out-after {
	&::after {
		content: "\f26f";
	}
}

.vl-vi-zoom-out {
	@include vi-zoom-out;
}

.vl-vi-zoom-out--after {
	@include vi-zoom-out-after;
}
