.vlw__contact__toggle  {
    background: $theme-secondary-color !important;
    background-color: $theme-secondary-color !important;
}

.vlw__contact__toggle::after {
    border-right: 13.5px solid $theme-secondary-color !important;
}

.vlw .vlw__primary-bar__brand__umbrella--secondary:hover::after, .vlw .vlw__primary-bar__brand__umbrella--secondary:focus::after {
    border-left: 13.5px solid $theme-secondary-color !important;
}

.vlw .vlw__primary-bar__brand__umbrella--secondary:hover::before, .vlw .vlw__primary-bar__brand__umbrella--secondary:focus::before {
    background-color: $theme-secondary-color !important;
}

.vlw .vlw__primary-bar__brand__host:hover, .vlw .vlw__primary-bar__brand__host:focus {
    color: $theme-primary-color-dark !important;
}

.vlw .vlw__primary-bar__brand__umbrella[href]:hover, .vlw .vlw__primary-bar__brand__umbrella[href]:focus {
    color: $theme-primary-color-dark !important;
}

.vlw .vlw__primary-bar__progress {
    background-color: $theme-secondary-color !important;
}