// Positioning the loader to center screen
.loader-container {
	min-height: 40rem;
	position: relative;

	.loader {
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}
}

// In collapse block the loader jumps when min-height is set.
.collapse-block__content .loader-container{
	min-height: auto;
}
