// ==========================================================================
// Mapped colors
// Color variables based on their function or role, to see the color values
// see _colorsRaw.scss
// ==========================================================================
$page-bg: $white !default;

// ================================
// Text colors
// ================================
$text-color: $mine-shaft !default;
$alt-text-color: $dove-gray !default;
$sub-text-color: $gray !default;
$placeholder-color: $silver-chalice !default;
$icon-color: $silver-chalice !default;

// ================================
// Default widely used colors
// ================================
$action-color: $vl-curious-blue; //$science-blue !default;
$action-fg-color: $page-bg !default;
$action-hover-color: darken($vl-curious-blue, 10%); //$curious-blue !default;
$action-active-color: darken($vl-curious-blue, 10%); //$endeavour !default;
$action-active-border-color: $vl-elephant; //$regal-blue !default;
$action-text-color: $action-color !default;
$action-text-hover-color: $action-hover-color !default;
$action-text-active-color: $action-active-color !default;
$action-secondary-color: $limeade !default;
$action-tertiary-color: $loblolly !default;
$action-tertiary-hover-color: darken($loblolly, 20%) !default;
$accent-color: $vl-pelorous; //$yellow !default;
$accent-fg-color: $page-bg !default;
$accent-alt-color: darken($accent-color, 5%) !default;
$border-color: $ghost !default;
$outline-color: $amber !default;
$form-label-message-color: $regent-gray !default;
$label-color-light: $regent-gray !default;

// ================================
// Backgrounds
// ================================
$alt-bg: $vl-athens-gray; //$athens-gray !default;
$alt-hover-bg: $athens-gray-light !default;
$overlay-bg: rgba($page-bg, 0.8) !default;


// ================================
// States
// ================================
$error-color: $mulberry !default;
$error-fg-color: $page-bg !default;
$error-hover-color: $tall-poppy !default;
$error-secondary-color: mix($azalea, $page-bg, 60%) !default;
$error-text-color: $error-color !default;
$warning-color: $amber !default;
$warning-text-color: $amber !default;
$warning-fg-color: $text-color !default;
$warning-hover-color: darken($warning-color, 5%) !default;
$warning-secondary-color: $off-yellow !default;
$success-color: $limeade !default;
$success-hover-color: $success-color !default;
$success-fg-color: $page-bg !default;
$success-secondary-color: $rum-swizzle !default;
$success-text-color: $success-color !default;
$bordered-content-border-color: $alt-bg !default;
$subtext-color: $alt-text-color !default;
$marker-bg-color: $yellow !default;
$marker-fg-color: $text-color !default;
$progress-color: $pink !default;

// ================================
// Disabled
// ================================
$disabled-color: $ghost !default;
$disabled-color-dark: darken($ghost, 20%);
$disabled-bg-color: mix($vl-athens-gray, $page-bg, 50%); //mix($athens-gray, $page-bg, 50%) !default;
$disabled-text-color: $alt-text-color !default;

// ================================
// Regions
// ================================
$alt-region-bg: $alt-bg !default;


// ================================
// Theme colors
// ================================

$theme-primary-color: $azure !default;
$theme-primary-color-dark: $vl-primary-blue-dark !default;
$theme-secondary-color: $azure;
$theme-border-color: $vl-border-color;
$theme-disabled-color: $vl-disabled-color;
$theme-actions-background: $vl-action-bg-color;