//old header styling

.vl-functional-header {
    margin-bottom: 2rem;
    color: $text-color;
    position: relative;
    min-width: 768px;
    background-color: $alt-bg;
    padding: 1.5rem 0;
    min-height: 6.5rem;
}

.vl-functional-header__actions {
    margin-top: -1.2rem;
    padding: 1.5rem .5rem 1rem;
    background: $theme-actions-background;
}

.vl-functional-header--has-actions::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: .3rem;
    background-color: $theme-actions-background;
    z-index: 2;
}