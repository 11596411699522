.vl-side-navigation__toggle {
    font-weight: 400;
}

.vl-side-navigation__item a {
    font-weight: 400;
}

.vl-vi-arrow-right-fat {
    color: $text-color;
}