// ==========================================================================
// Colors
// Are defined by the "Name That Color" principle.
// More info: http://chir.ag/projects/name-that-color/
// Below the standard color names you can find the used variables throughout Flanders UI.
// ==========================================================================
$white: #fff;
$black: #000;

// ================================
// Gray shaded
// ================================
$mine-shaft: #333332;
$dove-gray: #666;
$ghost: #cbd2da;
$athens-gray: #e8ebee;
$athens-gray-light: #f1f2f4;
$fuscous-gray: #434342;
$friar-gray: #80807d;
$cararra: #e5e5e1;
$gray-chateau: #9fa4aa;
$porcelain: #f3f5f6;
$geyser: #d3d9e0;
$silver-chalice: #b2b2b2;
$gray: #8c8c8c;
$ship-gray: #3b3b3c;
$loblolly: #c6cdd3;
$storm-dust: #5f6364;
$regent-gray: #8695a8;

$vl-tundora: #494949;

$vl-gallery: #EDEDED;
$vl-mercury: #E5E5E5;
$vl-alto: #D5D5D5;
$vl-dusty-gray: #989898;
$vl-athens-gray: #f2f2f2;

$vl-primary-blue: #2b92be;
$vl-primary-blue-dark: #227294;
$vl-secondary-blue: #39b9be;
$vl-border-color: #8c9ba7;
$vl-disabled-color: #cbd2da;
$vl-action-bg-color: #d3d3d3;


// ================================
// Brown shades
// ================================
$brown: #966729;
$frensh-beige: #a67f59;
$bon-jour: #e0d7d1;

// ================================
// Blue shades
// ================================
$science-blue: #05c;
$kashmir-blue: #557797;
$curious-blue: #003bb0;
$endeavour: darken($science-blue, 10%);
$regal-blue: #003a72;
$blue-bayoux: #607284;
$allports: #00789b;
$azure: #356297;

$vl-curious-blue: #2B92BE;
$vl-elephant: #15465B;

$vl-trendy-pink: #926DA5;
$vl-mulled-wine: #543F5E;


// ================================
// Yellow shades
// ================================
$amber: #ffc515;
$yellow: #ffe615;
$off-yellow: #fef9e5;
$school-bus-yellow: #fedd00;

// ================================
// Red shades
// ================================
$tall-poppy: #af2929;
$punch: #db3434;
$linen: #fbeaea;
$bahia: #a6cf00;
$azalea: #f8d6d6;
$persian-red: #c92c2c;
$mulberry: #c14485;

// ================================
// Green shades
// ================================
$napier-green: #238000;

$vl-elm: #1C7074;
$vl-jelly-bean: #2B979D;
$vl-pelorous: #39B9BE;


// ================================
// State colors
// ================================
$limeade: #8bae00;
$pistachio: #a3cc00;
$rum-swizzle: #f7fae5;
$map-background: #e5e3df;
$green: #a3cc00;
$background-green: #f6fae5;
$background-red: #fbeaea;
$background-yellow: #fef9e5;
$pink: #d53e5e;

// ================================
// Sharing colors
// ================================
$facebook-color: #36609f;
$twitter-color: #2caae1;
$linkedin-color: #0a6c9b;
$googleplus-color: #dc5442;