.state {
	&--active:before {
		color: $disabled-color;
	}

	&--completed:before {
		color: $theme-primary-color;
	}

	&--failed:before {
		color: $error-color;
	}

	&--open:before {
		content: "\f1ec";
		transform: rotate(90deg);
		transition: transform .3s ease;
	}

	&--closed:before {
		content: "\f1ec";
		transition: transform .3s ease;
	}
}

span.icon--active {
	color: $action-color;
}

.description-data__subdata--icon {

	&:hover,
	&:focus {
		color: $action-color;
		transition: color .3s ease;
	}
}

// Required state
label.required {
	&:after {
		content: '\F124';
		font-family: $icon-family;
		font-size: 50%;
		font-weight: bold;
		vertical-align: super;
		color: $accent-color;
	}
}

.margin--center {
	margin: 0 auto;
}

.ml--auto {
	margin-left: auto;
}

.no-text-decoration,
a.no-text-decoration {
	text-decoration: none;
}

.ta--right {
	text-align: right;
}