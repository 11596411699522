// h3 title with theme colored border
h3.vl-title--has-border {
	border-bottom: 2px solid $accent-color;
}

h1.vl-title--h1 {
	margin-bottom: 3rem;
}

// make form-group background transparent
div.vl-form__group {
	background-color: transparent;
	padding: 0;
}

// header color to custom theme
.iwgh2.iwgh2-redesign.iwgh2-redesign-alt a.iwgh2-extension-contact-toggle {
	background-color: $accent-color;
	border-right: 18px solid $accent-color;
}

// infoblock text color
i.vl-infoblock__header__icon {
	color: $white;
}

//data table cusor
.vl-data-table--hover tr {
	cursor: pointer;
}

// vl-region remove top padding
.vl-region:first-child {
	padding-top: 3rem;
}

// Give all link elements a pointer cursor
a {
	cursor: pointer;
}

// Dialog backdrop
.modal-open:after {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10;
	background-color: $overlay-bg;
	content: '';
	width: 100%;
	height: 100%;
}


// table headers rotated in large table
.vl-data-table.data-table--large {
	th {
		height: 140px;
		white-space: nowrap;
		vertical-align: middle;

		& > div {
			transform: translate(-6px, 51px) rotate(315deg);
			width: 30px;

			& > span {
				padding: 5px 10px;
			}
		}

		&:first-child div {
			transform: translate(-10px, 50px) rotate(0deg);
		}
	}
}

.vl-description-data__data {
	font-size: 1.2rem;
}

app-modal.vl-modal-dialog {
	overflow: auto;
}

// vl-document not full width clickable
.vl-document {
	display: inline-flex;
}

// Sidenav word-wrap
.vl-side-navigation__item p {
	max-width: 90%;
}

/* Flexbox in IE
===================
*/
// fix child overflow issue in IE when using flexbox
.vl-alert__content {
	flex: 1;
}

// Header not scaling in IE
.vl-content-header__bg .visual {
	width: 100%;
	height: auto;
}

// Show lists in texts
.show-lists em {
	font-style: italic;
}
.show-lists ul {
	list-style-type: disc;
	margin-left: 1rem;
}
.show-lists ol {
	list-style-type: decimal;
	margin-left: 1rem;
}

// Always show opened drop downs on top of other elements
.vl-select__list--dropdown {
	z-index: 100 !important;
}