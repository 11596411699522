.collapse-block {
	border: .1rem solid $border-color;
	margin-bottom: 2rem;

	&__top {
		background-color: $alt-bg;
		border-bottom: .1rem solid $border-color;
		padding: 1rem;

		&:hover {
			background-color: darken($alt-bg, 5%);
			transition: background-color .3s ease;
			cursor: pointer;
		}
	}

	&__content {
		padding: 1rem;
	}

	&__title {
		width: 50%;
	}

	&--new {
		border-left: .3rem solid $accent-color;
	}
}