i.vl-infoblock__header__icon {
    color: $white;
}

.vl-infoblock__header__icon {
    display: block;
    flex: 0 0 auto;
    background-color: transparent;
    background: linear-gradient(-110deg, transparent 2rem, $theme-secondary-color 2rem);
    content: "";
    font-size: 3rem;
    color: $text-color;
    width: 7rem;
    height: 6rem;
    text-align: center;
    padding-right: 1rem;
    margin-right: 1rem;
}

.vl-infoblock--contact .vl-infoblock__header__icon::before {
    content: "\f102";
}

.vl-infoblock__header__icon.vl-icon,
.vl-infoblock__header__icon {
    border-radius: 0;
}

.vl-infoblock--contact .vl-infoblock__header__icon::after,
.vl-infoblock--contact .vl-infoblock__header__icon::before {
    font-family: "vlaanderen-icon" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
}

.vl-infoblock__header::after {

    display: block;
    flex: 1;
    background-color: $alt-bg;
    content: "";
    height: .3rem;
    top: 50%;
    right: 0;
    left: 8rem;
}

.vl-infoblock__header {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    line-height: 6rem;
}

.vl-infoblock__title {
    font-size: 3.4rem;
    font-weight: 400;
    position: relative;
    display: inline-block;
    line-height: 1;
    padding-right: 2.5rem;
    margin-bottom: 0;
    z-index: 2;
}

.vl-infoblock {
    margin-top: 3rem;
    margin-bottom: 5rem;
}