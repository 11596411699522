.skeleton {
	/*
	Properties for skeleton loading style
	*/
	$skeleton-bg: #EDEDED;

	$skeleton-titleheight: 2.4rem;
	$skeleton-border: 2px solid $skeleton-bg;

	width: 100%;

	&--files {
		.skeleton__file {
			margin-bottom: 2rem;

			span {
				width: 6rem;
				height: 6rem;
				background-color: $skeleton-bg;
				display: inline-block;
				vertical-align: middle;
				margin-right: 2rem;
			}

			p {
				width: 180px;
				height: 1.6rem;
				background-color: $skeleton-bg;
				display: inline-block;
			}
		}

		.skeleton__upload {
			width: 100%;
			height: 5rem;
			border: 2px dashed $skeleton-bg;
			text-align: center;

			span {
				width: 40%;
				height: 1.6rem;
				background-color: $skeleton-bg;
				display: inline-block;
				margin: 1.5rem 0;
			}
		}

		.skeleton__form {
			margin-bottom: 1rem;
			display: none;

			span {
				background-color: $skeleton-bg;
				display: inline-block;
				vertical-align: middle;


				&:first-child {
					width: 10rem;
					height: 1.6rem;
					margin-right: 9rem;
				}

				&:nth-child(2) {
					width: 40rem;
					height: 1.6rem;
				}
			}
		}
	}

	&__header {
		width: 100%;
		border-bottom: $skeleton-border;
		padding-bottom: 5px;
		margin-bottom: 2rem;

		.skeleton__title {
			width: 40%;
			height: $skeleton-titleheight;
			background-color: $skeleton-bg;
		}
	}

	&__content {}
}

.collapse-block {
	.skeleton__header,
	.skeleton__upload {
		display: none;
	}

	.skeleton__form {
		display: block;
	}
}

.shine {
	width: 100%;
	-webkit-animation-name: ShineAnimation;
	-webkit-animation-duration: 5s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: cubic-bezier(.12, .89, .98, .47);
}

@-webkit-keyframes ShineAnimation {
	from {
		background-repeat: no-repeat;
		background-image: -webkit-linear-gradient(top left,
			rgba(255, 255, 255, 0.0) 0%,
			rgba(255, 255, 255, 0.0) 45%,
			rgba(255, 255, 255, 0.5) 48%,
			rgba(255, 255, 255, 0.8) 50%,
			rgba(255, 255, 255, 0.5) 52%,
			rgba(255, 255, 255, 0.0) 57%,
			rgba(255, 255, 255, 0.0) 100%);
		background-position: -250px -250px;
		background-size: 600px 600px
	}

	to {
		background-repeat: no-repeat;
		background-position: 250px 250px;
	}
}
