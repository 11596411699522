.vl-document__type .vl-vi {
    font-size: 5.5rem;
    position: absolute;
    left: -.5rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: $icon-color;
}

.vl-document__type__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    position: relative;
    border: .2rem solid $icon-color;
    border-radius: .2rem;
    width: 4.3rem;
    font-size: 1.2rem;
    line-height: 1;
    color: $alt-text-color;
    background-color: $white;
    text-align: center;
    font-weight: 400;
    height: auto;
    top: auto;
    left: auto;
}

.vl-document__type {
    min-height: 6rem;
    margin-right: 2rem;
    position: relative;
    padding: 3rem 0 0 2rem;
  }

  .vl-document {
    display: inline-flex!important;
    align-items: center;
    text-decoration: none;
  }