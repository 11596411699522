// ==========================================================================
// Flanders UI - Core
// The Flanders UI core is based on the ITCSS principle.
// Read more about ITCSS on
// https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/
// ==========================================================================
@charset "utf-8";




// ==========================================================================
// Settings
// All variables and general declarations.
// ==========================================================================

/*
** Set SCSS variables to meet your project's design.
** The examples below are set to use the predefined assets published in vl-ui-core package.
*/
$vl-link-visited-state: true;
$vl-pswp-assets-path: '~@govflanders/vl-ui-core/dist/img/lightbox/';
$vl-font-location: '~@govflanders/vl-ui-core/dist/font/flanders/';
$vl-icon-font-location: '~@govflanders/vl-ui-core/dist/font/iconfont/';

// Custom color palette included
@import "colorsRaw";
@import "colors";

@import "~@govflanders/vl-ui-core/src/scss/core";
@import "~@govflanders/vl-ui-core/src/scss/setting/general";
@import "~@govflanders/vl-ui-core/src/scss/setting/fonts";
@import "~@govflanders/vl-ui-core/src/scss/setting/breakpoints";
@import "~@govflanders/vl-ui-core/src/scss/setting/mobile";
@import "~@govflanders/vl-ui-core/src/scss/setting/grid";
@import "~@govflanders/vl-ui-core/src/scss/setting/page";
@import "~@govflanders/vl-ui-core/src/scss/setting/spacing";
@import "~@govflanders/vl-ui-core/src/scss/setting/zindex";
@import "~@govflanders/vl-ui-core/src/scss/setting/typography";
@import "~@govflanders/vl-ui-core/src/scss/setting/form";
@import "~@govflanders/vl-ui-core/src/scss/setting/badge";


// ==========================================================================
// Tools
// Mixins, extends, functions
// ==========================================================================
@import "~@govflanders/vl-ui-core/src/scss/tool";

// ==========================================================================
// Generic
// Normalize, body/html styling.
// ==========================================================================
// @import "~@govflanders/vl-ui-core/src/scss/generic";
@import "~@govflanders/vl-ui-core/src/scss/generic/reset";
@import "~@govflanders/vl-ui-core/src/scss/generic/base";
@import "~@govflanders/vl-ui-core/src/scss/generic/typography";
@import "iconfont";

// ==========================================================================
// Elements
// (limited) DOM element styling.
// ==========================================================================
@import "~@govflanders/vl-ui-core/src/scss/element";

// ==========================================================================
// Objects
// class-based selectors which define undecorated design patterns
// ==========================================================================
@import "~@govflanders/vl-ui-core/src/scss/object";


// ==========================================================================
// Components
// ==========================================================================

// Grid


// Atoms
@import "~@govflanders/vl-ui-button/src/scss/button";
@import "~@govflanders/vl-ui-icon/src/scss/icon";
@import "~@govflanders/vl-ui-loader/src/scss/loader";
@import "~@govflanders/vl-ui-badge/src/scss/badge";
@import "~@govflanders/vl-ui-pill/src/scss/pill";
@import "~@govflanders/vl-ui-pill-input/src/scss/pill-input";


// Forms
@import "~@govflanders/vl-ui-form-structure/src/scss/form-structure";
@import "~@govflanders/vl-ui-form-message/src/scss/form-message";
@import "~@govflanders/vl-ui-select/src/scss/select";
@import "~@govflanders/vl-ui-input-field/src/scss/input-field";
@import "~@govflanders/vl-ui-input-group/src/scss/input-group";
@import "~@govflanders/vl-ui-input-addon/src/scss/input-addon";
@import "~@govflanders/vl-ui-datepicker/src/scss/datepicker";
@import "~@govflanders/vl-ui-textarea/src/scss/textarea";
@import "~@govflanders/vl-ui-upload/src/scss/upload";
@import "~@govflanders/vl-ui-checkbox/src/scss/checkbox";
@import "~@govflanders/vl-ui-form-message/src/scss/form-message";


// Components
@import "~@govflanders/vl-ui-functional-header/src/scss/functional-header";
@import "~@govflanders/vl-ui-tabs/src/scss/tabs";
@import "~@govflanders/vl-ui-description-data/src/scss/description-data";
@import "~@govflanders/vl-ui-data-table/src/scss/data-table";
@import "~@govflanders/vl-ui-side-navigation/src/scss/side-navigation";
@import "~@govflanders/vl-ui-document/src/scss/document";
@import "~@govflanders/vl-ui-action-group/src/scss/action-group";
@import "~@govflanders/vl-ui-pager/src/scss/pager";
@import "~@govflanders/vl-ui-content-header/src/scss/content-header";
@import "~@govflanders/vl-ui-alert/src/scss/alert";
@import "~@govflanders/vl-ui-spotlight/src/scss/spotlight";
@import "~@govflanders/vl-ui-infoblock/src/scss/infoblock";
@import "~@govflanders/vl-ui-contact-card/src/scss/contact-card";
@import "~@govflanders/vl-ui-functional-header/src/scss/functional-header";


// JS Components
@import "~@govflanders/vl-ui-tooltip/src/scss/tooltip";
@import "~@govflanders/vl-ui-sticky-bar/src/scss/sticky-bar";
@import "~@govflanders/vl-ui-modal/src/scss/modal";


// Utilities
@import "~@govflanders/vl-ui-util/src/scss/util";
@import "~@govflanders/vl-ui-typography/src/scss/typography";
@import "~@govflanders/vl-ui-titles/src/scss/titles";



