.scroll-to-top {
	position: fixed;
	bottom: 15px;
	right: 15px;
	opacity: 0;
	transition: all .2s ease-in-out;
	background-color: $white;
	z-index: 1;
	
	button:active,
	button:focus {
		outline: none;
	}
}

.show-scrollTop {
	opacity: 1;
	transition: all .2s ease-in-out;
}
