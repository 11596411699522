* {
	box-sizing: border-box;
}

*:before,
*:after {
	box-sizing: border-box;
}

*::selection {
	background: rgba($theme-primary-color, .3);
}

html,
body {
	height: 100%;
	position: relative;
}

.main-container {
	min-height: 100vh;
	/* will cover the 100% of viewport */
	overflow: hidden;
	display: block;
	position: relative;
	padding-bottom: 136px;
	/* height of your footer */
}

.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
}

.button--dirty {
	min-width: 23rem;

	span {
		width: 100%;
	}
}

.vl-layout {
	display: block;
}

// overwrite the color of text on the help section of the wu header
.vlw__contact__toggle__label {
	color: $white !important;
}

// overwrite the color of the icon on the help section of the wu header
.vlw__contact__toggle__icon {
	fill: $white !important;
}