.vl-button {
    background-color: $theme-primary-color;
    font-weight: 400;
}

.vl-button:focus,
.vl-button:hover {
    background-color: $theme-primary-color-dark;
    text-decoration: none;
    color: $white;
}

.vl-button--tertiary {
    background-color: transparent;
    color: $theme-primary-color;
}

.vl-button--disabled {
    background-color: $theme-disabled-color;
    color: $white;
}

.vl-button--tertiary:active,
.vl-button--tertiary:focus,
.vl-button--tertiary:hover {
    color: $theme-primary-color;
    background-color: transparent;
    border-color: $theme-border-color;
    box-shadow: inset 0 0 0 .2rem $theme-border-color;
}

.vl-button--disabled:focus,
.vl-button--disabled:hover {
    background-color: $theme-disabled-color;
    text-decoration: none;
    color: $white;
    box-shadow: none;
    border: none;
    color: $white;
}

.vl-upload__element__label {
    color: $theme-primary-color;
}